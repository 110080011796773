import { createContext, useContext, useState } from "react";

const PurchaseProccessContext = createContext(undefined);

export const PurchaseProccessProvider = ({children}) => {

    //STATES
    const [deliveryType, setDeliveryType] = useState(1); // 1 : DELIVERY 0 : PICKUP
    const [paymentMethod, setPaymentMethod] = useState(1); // 1: WOMPI 2: SISTECREDITO
    const [cedula, setCedula] = useState("");

    const values = {
        deliveryType,
        setDeliveryType,
        paymentMethod,
        setPaymentMethod,
        cedula,
        setCedula
    }

    return (
        <PurchaseProccessContext.Provider
            value={values}
        >
            {children}
        </PurchaseProccessContext.Provider>
    );
}

export const usePurchaseProccess = () => useContext(PurchaseProccessContext);