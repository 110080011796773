import './purchase_product_item.css';
import imagePlaceholder from '../../assets/img/jpg/product_placeholder.jpg';
import { useCart } from "../../context/CartContext";
import { backendEnv } from "../../env";

export const PurchaseProductItem = ({item}) => {
    const {getLocalePrice} = useCart();

    return (
        <div className='purchase_product_item'>
            <div className='pic'>
                <img src={item.image !== "" ? backendEnv.url + item.image : imagePlaceholder} alt='' />
            </div>
            <div className='info'>
                <h2>
                    {item.name}
                </h2>
                <p>
                    Cantidad: {item.qty} &nbsp;
                    {
                        item.variations.map((variation) => {
                            return (
                                <span>{variation.name}: {variation.option}&nbsp;</span>
                            )
                        })
                    }
                </p>
            </div>
            <div className='total'>
                {getLocalePrice(item.total)}
            </div>
        </div>
    );
}