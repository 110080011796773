import './profile_side_menu.css';
import { Link, useLocation } from 'react-router-dom';
import { UserIcon } from '../../assets/icons/UserIcon';
import { BagIcon } from '../../assets/icons/BagIcon';
import { MapIcon } from '../../assets/icons/MapIcon';
import { ExitIcon } from '../../assets/icons/ExitIcon';
import { useAuth } from '../../context/AuthContext';

export const ProfileSideMenu = () => {
    const location = useLocation();
    const { logout } = useAuth();

    return (
        <div className="profile_side_menu">
            <Link to={'/perfil'} className={location.pathname === '/perfil' ? "active" : ""}>
                <UserIcon />
                Mi cuenta
            </Link>
            <Link to={'/compras'} className={location.pathname === '/compras' ? "active" : ""}>
                <BagIcon />
                Mis compras
            </Link>
            <Link to={'/direcciones'} className={location.pathname === '/direcciones' ? 'active' : ''}>
                <MapIcon />
                Direcciones guardadas
            </Link>
            <Link onClick={() => logout()}>
                <ExitIcon />
                Cerrar sesión
            </Link>
        </div>
    );
}