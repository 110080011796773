import './index.css';
import slide1 from '../../assets/img/jpg/ford_card_slider.jpg';
import slide2 from '../../assets/img/jpg/main_slide2.jpg';
import slide3 from '../../assets/img/jpg/main_slide3.jpg';
import slide4 from '../../assets/img/jpg/main_slide4.jpg';
import purpleLightCar from '../../assets/img/jpg/purple_light_car.png';
import purpleLightCarResponsive from '../../assets/img/jpg/purple_light_car_responsive.jpg';
import screenRadio from '../../assets/img/jpg/screen_radio.jpg';
import LightCategorySuv from '../../assets/img/jpg/light_category_suv.jpg';
import cuidadoEmbellecimiento from '../../assets/img/jpg/cuidado_embellecimiento.jpg';
import alarmas from '../../assets/img/jpg/alarmas.jpg';
import servicioElectrico from '../../assets/img/png/servicio_electrico.png';
import instalacionAccesorios from '../../assets/img/png/instalacion_accesorios.png';
import mzCar from '../../assets/img/png/mz_car.png';
import fachada from '../../assets/img/jpg/fachada.jpg';
import manImage1 from '../../assets/img/png/man1.png';
import sistecreditoImg from '../../assets/img/png/sistecredito.png'
import { Header } from '../../components/Header/Header';
import { Link } from 'react-router-dom';
import { Footer } from '../../components/Footer/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, EffectFade } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import { WhatsappBtn } from '../../components/WhatsappBtn/WhatsappBtn';

export function Index() {
    return (
        <>
            <WhatsappBtn />
            <section className='main_section'>
                <Header />
                {/* <div className='demo_slider'>
                    <img src={slide1} alt='' />
                </div> */}
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={true}
                    effect={'fade'}
                    speed={1000}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, EffectFade]}
                    className="slider"
                >
                    <SwiperSlide className='slide'>
                        <img src={slide1} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='slide'>
                        <img src={slide2} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='slide'>
                        <img src={slide3} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='slide'>
                        <img src={slide4} alt='' />
                    </SwiperSlide>
                </Swiper>
            </section>
            <section className='led_section'>
                <div className='container'>
                    <div className='content'>
                        <h1>
                            LUZ LED
                        </h1>
                        <h2>
                            Ilumina tu Camino con Elegancia
                        </h2>
                        <p>
                            Descubre la magia de la iluminación LED para tu vehículo. Nuestras luces LED no solo brindan una iluminación excepcional, sino que también agregan un toque de estilo único a tu auto. Ya sea que busques mejorar la visibilidad en la carretera o realzar la apariencia de tu vehículo, nuestras luces LED son la elección perfecta.
                        </p>
                        <Link to='/luces-led'>
                            Ver mas...
                        </Link>
                    </div>
                    <img src={purpleLightCar} className='bk' alt='' />
                    <img src={purpleLightCarResponsive} className='bk responsive' alt='' />
                </div>
            </section>
            <section className='categories_section'>
                <div className='container'>
                    <div className='item'>
                        <div className='content'>
                            <h2>
                                RADIOS
                            </h2>
                            <p>
                                Descubre un mundo sorprendente. Aprovecha al máximo el potencial de tu radio en el camino. Te ofrecemos una experiencia única con nuestra variedad en radios para vehículo, desde el tradicional radio en cinta hasta nuestros radios Android.
                            </p>
                            <Link to='https://api.whatsapp.com/send?phone=573113739037' target='_blank'>
                                Comprar
                            </Link>
                        </div>
                        <img src={screenRadio} className='bk' alt='' />
                    </div>
                    <div className='item'>
                        <div className='content'>
                            <h2>
                                ILUMINACIÓN
                            </h2>
                            <p>
                                Enciende el camino a dónde quiera que vayas. Con nuestra diversidad de luces LED encontrarás la mejor seguridad y confianza al momento de conducir en la vía. 
                            </p>
                            <Link to='https://api.whatsapp.com/send?phone=573113739037' target='_blank'>
                                Comprar
                            </Link>
                        </div>
                        <img src={LightCategorySuv} className='bk' alt='' />
                    </div>
                    <div className='item'>
                        <div className='content'>
                            <h2>
                                CUIDADO Y EMBELLECIMIENTO
                            </h2>
                            <p>
                                “Tu vehículo merece lo mejor”. Nuestra sección de productos de cuidado y embellecimiento, están diseñados para mantener tu auto impecable como se merece. Desde limpieza básica hasta detalles meticulosos, estamos comprometidos con ayudar a resaltar la belleza de tu automóvil al máximo. 
                            </p>
                            <Link to='https://api.whatsapp.com/send?phone=573113739037' target='_blank'>
                                Comprar
                            </Link>
                        </div>
                        <img src={cuidadoEmbellecimiento} className='bk' alt='' />
                    </div>
                    <div className='item'>
                        <div className='content'>
                            <h2>
                                ALARMAS
                            </h2>
                            <p>
                                Deja tu auto estacionado sin tener que preocuparte, “la tranquilidad es primero”. Protege tu automóvil en todo momento. Nuestras alarmas de seguridad están diseñadas para brindar la confianza y protección que mereces en todo momento.
                            </p>
                            <Link to='https://api.whatsapp.com/send?phone=573113739037' target='_blank'>
                                Comprar
                            </Link>
                        </div>
                        <img src={alarmas} className='bk' alt='' />
                    </div>
                </div>
                {/* <Link>
                    VER TODAS LAS CATEGORIAS
                </Link> */}
            </section>
            {/* <section className='volvo_section'>
                <div className='content'>
                    <div className='content_text'>
                        <h2>
                            AQUI UNA <span>FRASE</span>
                        </h2>
                        <div className='divider'></div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan. ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.
                        </p>
                        <Link>
                            TIENDA VIRTUAL
                        </Link>
                    </div>
                    <div className='content_media'>
                        <div className='image'>
                            <img src={volvo} alt='' />
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='sistecredito_section'>
                <div className='container'>
                    <div className='content'>
                        <h2>
                            COMPRA A <span>CUOTAS</span>
                        </h2>
                        <h2 className='t2'>
                            CON <span>SISTECRÉDITO</span>
                        </h2>
                        <img src={sistecreditoImg} alt='' />
                    </div>
                    <div className='media'>
                        <img src={manImage1} alt='' />
                    </div>
                </div>
            </section>
            <section className='services_section' id='servicios'>
                <h2>
                    NUESTROS
                    <br />
                    <span>
                        SERVICIOS
                    </span>
                </h2>
                <div className='service'>
                    <div className='service_img'>
                        <div className='container'>
                            <img src={servicioElectrico} alt='' />
                        </div>
                    </div>
                    <div className='content'>
                        <div className='container'>
                            <h2>
                                SERVICIO ELÉCTRICO AUTOMOTRIZ
                            </h2>
                            <div className='divider'></div>
                            <p>
                                Nuestro equipo eléctrico automotriz de primera, te apoyará a resolver desde problemas eléctricos simples hasta diagnósticos complejos, buscamos que tu vehículo siempre se encuentre en óptimas condiciones a donde quiera que vayas.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='service'>
                    <div className='service_img'>
                        <div className='container'>
                            <img src={instalacionAccesorios} alt='' />
                        </div>
                    </div>
                    <div className='content'>
                        <div className='container'>
                            <h2>
                                INSTALACIÓN DE ACCESORIOS
                            </h2>
                            <div className='divider'></div>
                            <p>
                                Ofrecemos un servicio especializado de instalación de accesorios para tu vehículo en nuestro local.
                                Nuestro equipo cuenta con la experiencia necesaria para garantizar una instalación segura y de alta calidad.
                                Desde luces LED hasta sistemas de audio avanzados, estamos aquí para personalizar tu vehículo según tus preferencias.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='location_section'>
                <h2>
                    DONDE
                    <br />
                    <span>ENCONTRARNOS</span>
                </h2>
                <div className='container'>
                    <div className='content'>
                        <p>
                            Recuerda visitarnos en nuestro local, estamos ubicados Carrera 25 #46-54 Av Paralela, estamos listos para atenderte y brindarte la mejor atención.
                        </p>
                        <Link to='https://api.whatsapp.com/send?phone=573113739037' target='_blank'>
                            Agenda tu visita
                        </Link>
                    </div>
                    <div className='map'>
                        <div className='map_container'>
                            <iframe src="https://maps.google.com/maps?width=700&amp;height=200&amp;hl=en&amp;q=Cra.%2025%20%2346-54%2C%20Manizales%2C%20Caldas+(Only%20Car)&amp;ie=UTF8&amp;t=&amp;z=17&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        </div>
                    </div>
                </div>
                <img src={mzCar} className='car' alt='' />
            </section>
            <section className='about_section' id='nosotros'>
                <div className='container'>
                    <div className='title'>
                        <h2>
                        ¿Quiénes <br /> somos?
                        </h2>
                    </div>
                    <div></div>
                    <div className='img_col'>
                        <div className='image'>
                            <img src={fachada} alt='' />
                        </div>
                    </div>
                    <div className='text_col'>
                        <p>
                            Contamos con más de 12 años de experiencia en venta e instalación de lujos y accesorios para todo tipo de vehículos, que busca ser reconocida por ser confiable, efectiva y sólida ofreciendo a nuestros clientes soluciones innovadoras, funcionales y de alta calidad. Contamos con la estructura e instalaciones necesarias para cumplir con la demanda de nuestros clientes y así prestar un servicio de alta calidad.
                        </p>
                    </div>
                </div>
            </section>
            <section className='testimonial_section'>
                <h2>
                    TESTIMONIOS
                </h2>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    loop={true}
                    speed={1000}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        996: {
                            slidesPerView : 2
                        }
                    }}
                    modules={[Autoplay]}
                    className="slider"
                >
                    <SwiperSlide className='slide'>
                        <div className='slide_item'>
                            <h2>
                                German Andrés
                            </h2>
                            <div className='stars'>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                            </div>
                            <p>
                                Que gran atención, muy buena variedad de productos y los mejores precios, además cuentan con gran personal calificado para la instalación de todos sus lujos y accesorios.
                                Super recomendado los invito a que visiten este almacén para que consientan sus vehículos
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='slide'>
                        <div className='slide_item'>
                            <h2>
                                Hernan Cordoba
                            </h2>
                            <div className='stars'>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                            </div>
                            <p>
                                Excelente servicio y el mejor surtido en lujos para tu vehículo, compré unas luces led 4 que me brinda la mejor iluminación en mi vehículo .las recomiendo y les recomiendo onlycar en Manizales son los mejores ....
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='slide'>
                        <div className='slide_item'>
                            <h2>
                                Cesar Ramirez
                            </h2>
                            <div className='stars'>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                            </div>
                            <p>
                                Excelente atención, servicio y asesoría en sus productos. Super recomendados.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='slide'>
                        <div className='slide_item'>
                            <h2>
                                Maria Teresa
                            </h2>
                            <div className='stars'>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                            </div>
                            <p>
                                Recomendado que atención y todos los productos que venden, las luces led de mi carro y la antena tiburón encantada que, gracias only car
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='slide'>
                        <div className='slide_item'>
                            <h2>
                                Manuel Alejandro
                            </h2>
                            <div className='stars'>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                            </div>
                            <p>
                                Muy buena atención,variedad de productos y unos excelentes precios con una excelente calidad. Totalmente recomendado!!
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='slide'>
                        <div className='slide_item'>
                            <h2>
                                Paula Arcila
                            </h2>
                            <div className='stars'>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                            </div>
                            <p>
                                Excelente servicio, super amables en la atención, muy recomendado, ademas te brindan una excelente asesoría.
                            </p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </section>
            <Footer />
        </>
    );
}