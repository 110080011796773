import './qty_input.css';
import { BarLoader } from '../BarLoader/BarLoader';
import { forwardRef, useImperativeHandle } from "react";
import { useState } from 'react';
import { useEffect } from 'react';

export const QtyInput = forwardRef(({initalValue, limit, callback}, ref) => {
    useImperativeHandle(ref, () => {
        return {
            loading,
            setLoading,
        };
    });

    //STATES
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(initalValue ? initalValue : 1);

    //FUNCTIONS
    const add = () => {
        if (limit) {
            if ((value + 1) > limit) {
                return;
            }
        }
        
        setValue(value + 1);
    }

    const remove = () => {
        if ((value - 1) < 1) {
            return;
        }

        setValue(value - 1);
    }

    //EFFECTS
    useEffect(() => {
        if (callback) {
            callback(value);
        }
    }, [value]);

    useEffect(() => {
        setValue(initalValue);
    }, [initalValue]);

    return (
        <div className="qty_input_container">
            <button disabled={loading} onClick={remove}>
                <i className="fa-solid fa-minus"></i>
            </button>
            <input type='text' value={value} disabled={loading} />
            <button disabled={loading} onClick={add}>
                <i className="fa-solid fa-plus"></i>
            </button>
            {
                loading && (
                    <div className='loader'>
                        <BarLoader />
                    </div>
                )
            }
        </div>
    );
});