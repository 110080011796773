import './login.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/img/png/logo_alternativo.png';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';

export const Login = () => {
    //HOOKS
    const { login, isAuth } = useAuth();
    const navigate = useNavigate();

    //STATES
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");

    //FUNCTIONS
    const postLogin = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setErrorMsg("");

        login(email, pwd, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];

                if (res.success) {
                    navigate('/catalogo', {replace: true});
                } else {
                    if (res.error_code === "wrong_credentials") {
                        setErrorMsg("Correo o contraseña incorrectos.");
                    } else if (res.error_code === "validator_exception") {
                        setErrorMsg("Debes ingresar el correo y contraseña");
                    }
                }
            } else {
                setErrorMsg("Error del servidor, intenta de nuevo en un momento.")
            }
        });
    }

    const goToRegister = () => {
        navigate('/registro', {replace: true});
    }

    //EFFECTS
    useEffect(() => {
        // if (isAuth !== null) {
        //     if (isAuth) {
        //         navigate('/catalogo', {replace: true});
        //     }
        // }
    }, [isAuth]);

    return (
        <>
            <div className='login_app_body'>
                <div className='header'>
                    <div className='body'>
                        <Link className='logo'>
                            <img src={logo} alt='' />
                        </Link>
                    </div>
                </div>
                <div className='form'>
                    <h1>
                        Ingresa tu correo y contraseña
                    </h1>
                    {
                        errorMsg !== "" && (
                            <div className='error_msg'>
                                {errorMsg}
                            </div>
                        )
                    }
                    <div className='fancy_input'>
                        <label>Correo</label>
                        <input type='text' placeholder='ejemplo@correo.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='fancy_input'>
                        <label>Contraseña</label>
                        <input type='password' value={pwd} onChange={(e) => setPwd(e.target.value)} />
                    </div>
                    <Link className='forgot'>
                        ¿Olvidaste tu contraseña?
                    </Link>

                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                    
                    <button className='login_btn' onClick={postLogin} disabled={loading}>
                        Iniciar sesión
                    </button>
                    <button className='signup_btn' onClick={goToRegister} disabled={loading}>
                        Crear cuenta
                    </button>
                </div>
            </div>
        </>
    );
}