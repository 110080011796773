import './lights.css';
import carRainImage from '../../assets/img/jpg/car_rain.jpg';
import dodgeImage from '../../assets/img/jpg/dodge.jpg';
import carLed from '../../assets/img/jpg/black_car_led.jpg';
import ledImage1 from '../../assets/img/jpg/leds1.jpg';
import ledImage2 from '../../assets/img/jpg/leds2.jpg';
import ledImage3 from '../../assets/img/jpg/leds3.jpg';
import ledImageN1 from '../../assets/img/jpg/leds_new1.jpg';
import ledImageN2 from '../../assets/img/jpg/leds_new2.jpg';
import ledcar1 from '../../assets/img/jpg/led_car1.jpg';
import ledcar2 from '../../assets/img/jpg/led_car2.jpg';
import ledcar3 from '../../assets/img/jpg/led_car3.jpg';
import foto_carro_led2 from '../../assets/img/jpg/foto_carro_led2.jpeg';
import foto_carro_led1 from '../../assets/img/jpg/foto_carro_led1.JPG';
import halogenRoad1 from '../../assets/img/jpg/halogen_road1.jpg.png';
import ledRoad1 from '../../assets/img/jpg/led_road1.jpg.png';
import sistecredito from '../../assets/img/png/sistecredito.png';
import workingImg from '../../assets/img/jpg/working1.jpg';
import { Header } from "../../components/Header/Header";
import { CheckIcon } from '../../assets/icons/CheckIcon';
import { Footer } from '../../components/Footer/Footer';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import { WhatsappBtn } from '../../components/WhatsappBtn/WhatsappBtn';

export function Lights() {
    return (
        <>
            <WhatsappBtn />
            <section className="lights_page_main_section">
                <Header />
                <div className='lights_main_container'>
                    <div className='content'>
                        <h1>
                            ILUMINACIÓN
                            <br />
                            <span>
                                LED
                            </span>
                        </h1>
                        <h2>
                            ¿Por qué cambia tus luces halógenas a LED?
                        </h2>
                        <div class="arrow">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className='media'>
                        <div className='image_container'>
                            <div className='img'>
                                <img src={carRainImage} alt='' />
                            </div>
                            <div className='square'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='led_advantages_section'>
                {/* <img src={jepImage} alt='' className='jeep_car' /> */}
                <div className='container'>
                    <div className='title'>
                        <h2>
                            VENTAJAS
                        </h2>
                        <div className='image_holder'>
                            <img src={dodgeImage} alt='' />
                        </div>
                    </div>
                    <div className='content'>
                        <div className='item'>
                            <div className='item_title'>
                                <CheckIcon />
                                <h2>
                                    Eficiencia Energética:
                                </h2>
                            </div>
                            <p>
                                Las luces LED son altamente eficientes, consumiendo menos energía que las halógenas. Esto prolonga la vida de la batería de tu vehículo.
                            </p>
                        </div>
                        <div className='item'>
                            <div className='item_title'>
                                <CheckIcon />
                                <h2>
                                    Mayor Durabilidad:
                                </h2>
                            </div>
                            <p>
                                Las luces LED son duraderas, duran mucho más que las halógenas, lo que significa menos reemplazos y ahorro de dinero a largo plazo.
                            </p>
                        </div>
                        <div className='item'>
                            <div className='item_title'>
                                <CheckIcon />
                                <h2>
                                    No pierden luminosidad:
                                </h2>
                            </div>
                            <p>
                                A lo largo de su vida útil, las luces LED mantienen una intensidad de luz constante, a diferencia de las luces halógenas que pueden disminuir con el tiempo.
                            </p>
                        </div>
                        <div className='item'>
                            <div className='item_title'>
                                <CheckIcon />
                                <h2>
                                    Contribución Ecológica y Sostenibilidad:
                                </h2>
                            </div>
                            <p>
                                Las luces LED reducen el impacto ambiental al consumir menos energía y requerir menos reemplazos, lo que disminuye la emisión de gases de efecto invernadero y la generación de residuos.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='led_gallery_section'>
                {/* <div className='item'>
                    <img src={ledImage1} alt='' />
                </div>
                <div className='item'>
                    <img src={ledImage2} alt='' />
                </div>
                <div className='item'>
                    <img src={ledImage3} alt='' />
                </div> */}
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    loop={true}
                    speed={1000}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        996: {
                            slidesPerView : 3
                        }
                    }}
                    modules={[Autoplay]}
                    className="slider"
                >
                    <SwiperSlide className='slide_item'>
                        <img src={ledImage1} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='slide_item'>
                        <img src={ledcar1} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='slide_item'>
                        <img src={ledImageN1} alt='' />
                    </SwiperSlide>
                    {/* <SwiperSlide className='slide_item'>
                        <img src={ledImage2} alt='' />
                    </SwiperSlide> */}
                    <SwiperSlide className='slide_item'>
                        <img src={foto_carro_led1} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='slide_item'>
                        <img src={ledImageN2} alt='' />
                    </SwiperSlide>
                    {/* <SwiperSlide className='slide_item'>
                        <img src={ledImage3} alt='' />
                    </SwiperSlide> */}
                    <SwiperSlide className='slide_item'>
                        <img src={foto_carro_led2} alt='' />
                    </SwiperSlide>
                </Swiper>
            </section>
            <section className='led_vs_section'>
                <div>
                    <img src={halogenRoad1} alt='' />
                    <h2>
                        LUZ HALÓGENA
                    </h2>
                </div>
                <div>
                    <img src={ledRoad1} alt='' />
                    <h2>
                        LUZ LED
                    </h2>
                </div>
            </section>
            <section className='led_sistecredito_section'>
                <div className='content'>
                    <h2>
                        <span className='sp1'>
                            RENUEVA
                        </span>
                        <br />
                        <span className='sp2'>
                            TUS LUCES Y MÁS 
                        </span>
                        <br />
                        CON SISTECRÉDITO
                    </h2>
                </div>
                <div className='media'>
                    {/* <img src={sisteCamaro} alt='' /> */}
                    <div className='image_container'>
                        <img src={carLed} className='car' alt='' />
                        <img src={sistecredito} className='siste' alt='' />
                    </div>
                </div>
            </section>
            <section className='led_visit_us_section'>
                <div className='media'>
                    <img src={workingImg} alt='' />
                </div>
                <div className='content'>
                    <h2>
                        Visítanos
                    </h2>
                    <p>
                        Nuestro equipo profesional
                        <br />
                        te espera
                    </p>
                </div>
            </section>
            <Footer />
        </>
    );
}