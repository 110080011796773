import './legal_pages.css';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';

export function ReturnsPolicy() {
    return (
        <div className='legal_page'>
            <Header />
            <div className='legal_content'>
                <h1>Politicas de cambios y devoluciones</h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Netus et malesuada fames ac. Amet mauris commodo quis imperdiet massa tincidunt nunc. Cursus vitae congue mauris rhoncus aenean. Sed lectus vestibulum mattis ullamcorper. Cursus eget nunc scelerisque viverra mauris in. Cras ornare arcu dui vivamus arcu felis. Velit laoreet id donec ultrices. Aliquam vestibulum morbi blandit cursus risus at ultrices mi. Arcu cursus vitae congue mauris. Vitae purus faucibus ornare suspendisse sed nisi lacus. Urna neque viverra justo nec ultrices dui sapien eget. Nec ullamcorper sit amet risus nullam eget felis eget nunc. Purus ut faucibus pulvinar elementum integer. Quis ipsum suspendisse ultrices gravida. Sagittis vitae et leo duis. Gravida quis blandit turpis cursus.
                    <br /><br />
                    Venenatis urna cursus eget nunc scelerisque viverra. Egestas quis ipsum suspendisse ultrices gravida dictum fusce. Molestie ac feugiat sed lectus vestibulum mattis. Nisl tincidunt eget nullam non. Mattis rhoncus urna neque viverra justo. Iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui. Egestas erat imperdiet sed euismod nisi porta lorem mollis aliquam. Feugiat vivamus at augue eget arcu dictum varius. Adipiscing elit pellentesque habitant morbi. Ante in nibh mauris cursus. Nisl suscipit adipiscing bibendum est. Egestas erat imperdiet sed euismod nisi. Sed risus ultricies tristique nulla aliquet. Pharetra pharetra massa massa ultricies mi quis hendrerit dolor magna.
                    <br /><br />
                    Aliquet bibendum enim facilisis gravida neque convallis a. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Aliquet lectus proin nibh nisl condimentum. Non arcu risus quis varius. Pellentesque habitant morbi tristique senectus et. Tristique senectus et netus et malesuada fames. Purus sit amet volutpat consequat mauris nunc congue nisi. Amet luctus venenatis lectus magna. Bibendum arcu vitae elementum curabitur vitae nunc sed velit. Quis auctor elit sed vulputate mi sit amet. Nunc aliquet bibendum enim facilisis gravida neque convallis. In ornare quam viverra orci sagittis eu volutpat odio. Dictum at tempor commodo ullamcorper. Nisi vitae suscipit tellus mauris a. Sit amet nisl purus in mollis.
                    <br /><br />
                    Massa vitae tortor condimentum lacinia quis vel eros donec. Vitae et leo duis ut diam quam nulla porttitor. Ac tincidunt vitae semper quis lectus nulla at volutpat. Fames ac turpis egestas maecenas pharetra. Ut diam quam nulla porttitor massa id neque aliquam. Scelerisque varius morbi enim nunc faucibus a pellentesque sit. Scelerisque varius morbi enim nunc. Sed nisi lacus sed viverra tellus in hac. Dui sapien eget mi proin sed libero. Leo urna molestie at elementum eu facilisis sed. Nisi est sit amet facilisis magna etiam tempor. Massa placerat duis ultricies lacus. Etiam dignissim diam quis enim lobortis scelerisque fermentum. Et malesuada fames ac turpis egestas integer eget. Risus viverra adipiscing at in tellus integer feugiat. Proin nibh nisl condimentum id venenatis a condimentum vitae. Eget magna fermentum iaculis eu non diam phasellus. Felis imperdiet proin fermentum leo.
                    <br /><br />
                    Vitae nunc sed velit dignissim. Tellus in hac habitasse platea dictumst vestibulum rhoncus est. Mi proin sed libero enim sed. Platea dictumst quisque sagittis purus sit amet volutpat consequat mauris. Blandit cursus risus at ultrices mi tempus imperdiet nulla. Egestas erat imperdiet sed euismod nisi porta lorem. In hac habitasse platea dictumst quisque sagittis. Sagittis purus sit amet volutpat. Diam sollicitudin tempor id eu nisl. Sagittis purus sit amet volutpat consequat mauris. Pretium lectus quam id leo in vitae turpis massa sed. Velit ut tortor pretium viverra suspendisse. Justo donec enim diam vulputate ut pharetra. Aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer.   
                </p>
            </div>
            <Footer />
        </div>
    );
}