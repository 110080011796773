import './legal_pages.css';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';

export function CookiesPolicy() {
    return (
        <div className='legal_page'>
            <Header />
            <div className='legal_content'>
                <h1>Política de Cookies</h1>
                <p>
                    El sitio web utiliza cookies para ayudar a personalizar tu experiencia en línea. Al acceder a Only Car Manizales, aceptaste utilizar las cookies necesarias.
                    <br /><br />
                    Una cookie es un archivo de texto que un servidor de páginas web coloca en tu disco duro. Las cookies no se pueden utilizar para ejecutar programas o enviar virus a tu computadora. Las cookies se te asignan de forma exclusiva y solo un servidor web en el dominio que emitió la cookie puede leerlas.
                    <br /><br />
                    Podemos utilizar cookies para recopilar, almacenar y rastrear información con fines estadísticos o de marketing para operar nuestro sitio web. Tienes la capacidad de aceptar o rechazar cookies opcionales. Hay algunas cookies obligatorias que son necesarias para el funcionamiento de nuestro sitio web. Estas cookies no requieren tu consentimiento ya que siempre funcionan. Ten en cuenta que al aceptar las cookies requeridas, también aceptas las cookies de terceros, que podrían usarse a través de servicios proporcionados por terceros si utilizas dichos servicios en nuestro sitio web, por ejemplo, una ventana de visualización de video proporcionada por terceros e integrada en nuestro sitio web.
                </p>
            </div>
            <Footer />
        </div>
    );
}