import './shopping_cart_summary.css';
import { useCart } from '../../context/CartContext';
import { useNavigate } from 'react-router-dom';

export const ShoppingCartSummary = () => {
    const { cart, getLocalePrice } = useCart();
    const navigate = useNavigate();

    return (
        <div className='shopping_cart_summary'>
            <div className='header'>
                <h2>
                    Resumen de la compra
                </h2>
            </div>
            <div className='body'>
                <div className='item'>
                    <h2>
                        Productos
                    </h2>
                    <span>
                        {cart && getLocalePrice(cart.cartData.subtotal)}
                    </span>
                </div>
                <div className='item'>
                    <h2>
                        Envio
                    </h2>
                    <span>
                        {cart && getLocalePrice(cart.cartData.deliveryPrice)}
                    </span>
                </div>
                <div className='item total'>
                    <h2>
                        Total
                    </h2>
                    <span>
                        {cart && getLocalePrice(cart.cartData.total)}
                    </span>
                </div>
                <button className='primary_btn' onClick={() => navigate('/compra/envio', {replace: true})}>
                    Finalizar compra
                </button>
            </div>
        </div>
    );
}