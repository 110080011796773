export const DeliveryDetails = ({deliveryDetails}) => {
    return (
        <div className='delivery_container'>
            <div className='header'>
                <h2>
                    Datos del envío
                </h2>
            </div>
            <div className='body'>
                <h2>
                    {
                        deliveryDetails.streetType
                    }
                    &nbsp;
                    {
                        deliveryDetails.street
                    }
                    &nbsp;
                    {
                        deliveryDetails.houseNumber1
                    }
                    &nbsp;-&nbsp;
                    {
                        deliveryDetails.houseNumber2
                    }
                    &nbsp;
                    Barrio: {deliveryDetails.neighborhood}
                </h2>
                <h2>
                    {deliveryDetails.city} - {deliveryDetails.state}
                </h2>
                <p>
                    Notas de envío: {deliveryDetails.addressDetails}
                </p>
                <p>
                    Transportadora: {deliveryDetails.carrier}
                </p>
                <p>
                    Guía de envío: {deliveryDetails.trackingNumber}
                </p>
            </div>
        </div>
    );
}