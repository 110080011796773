import './profile.css';
import { StoreHeader } from "../../components/StoreHeader/StoreHeader";
import { Footer } from '../../components/Footer/Footer';
import { ProfileSideMenu } from '../../components/ProfileSideMenu/ProfileSideMenu';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useServer } from '../../context/ServerContext';
import { endpoints } from '../../env';
import { ProfilePasswordChange } from '../../components/ProfilePasswordChange/ProfilePasswordChange';

export const Profile = () => {
    //HOOKS
    const { user, checkAuth } = useAuth();
    const { postRequest } = useServer();

    //STATES
    const [loading, setLoading] = useState();
    const [errorMsg, setErrorMsg] = useState("");
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    const [nameError, setNameError] = useState("");
    const [lastnameError, setLastnameError] = useState("");

    //FUNCTIONS
    const resetErrors = () => {
        setErrorMsg("");
        setNameError("");
        setLastnameError("");
    }

    const validateInput = () => {
        let pass = true;

        if (name.trim() === "") {
            setNameError("Se requiere el nombre.");
            pass = false;
        }

        if (lastname.trim() === "") {
            setLastnameError("Se requiere el apellido");
            pass = false;
        }

        return pass;
    }

    const updateUser = () => {
        if (loading) {
            return;
        }
        resetErrors("");

        if (!validateInput()) {
            return;
        }

        setLoading(true);

        const data = {
            name,
            lastname,
            phone,
        }

        postRequest(endpoints.user_update, data, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];
                if (res.success) {
                    checkAuth();
                } else {
                    setErrorMsg("Error del servidor, intenta de nuevo en un momento.");
                }
            } else {
                setErrorMsg("Error del servidor, intenta de nuevo en un momento.");
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (user !== null) {
            setName(user.name);
            setLastname(user.lastname);
            setPhone(user.phone);
            setEmail(user.email);
        }
    }, [user]);

    return (
        <>
            <StoreHeader />
            <div className="profile_app">
                <div className='body'>
                    <ProfileSideMenu />
                    <div className='profile_app_content'>
                        <div className='profile_container'>
                            <div className='header'>
                                <h2>
                                    Mi perfil
                                </h2>
                            </div>
                            <div className='profile_body'>
                                {
                                    errorMsg !== "" && (
                                        <div className='error_msg'>
                                            {errorMsg}
                                        </div>
                                    )
                                }
                                <div className='fancy_input_group'>
                                    <div className='fancy_input'>
                                        <label>Nombre</label>
                                        <input type='text' value={name} onChange={(e) => setName(e.target.value)} />
                                        <span>
                                            {nameError}
                                        </span>
                                    </div>
                                </div>
                                <div className='fancy_input_group'>
                                    <div className='fancy_input'>
                                        <label>Apellido</label>
                                        <input type='text' value={lastname} onChange={(e) => setLastname(e.target.value)} />
                                        <span>
                                            {lastnameError}
                                        </span>
                                    </div>
                                </div>
                                <div className='fancy_input_group'>
                                    <div className='fancy_input'>
                                        <label>Celular</label>
                                        <input type='text' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                    <div className='fancy_input'>
                                        <label>Correo</label>
                                        <input type='text' disabled value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                {
                                    loading && (
                                        <div className='loader'>
                                            <BarLoader />
                                        </div>
                                    )
                                }
                                
                                <div className='actions'>
                                    <div>

                                    </div>
                                    <div className='btns'>
                                        <button className='primary_btn' onClick={updateUser} disabled={loading}>
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ProfilePasswordChange />
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        </>
    );
}