import './addresses.css';
import { useEffect, useState } from "react";
import { ProfileSideMenu } from "../../components/ProfileSideMenu/ProfileSideMenu";
import { StoreHeader } from "../../components/StoreHeader/StoreHeader";
import { useUserAddresses } from "../../context/UserAddressesContext";
import { AddressModalItem } from "../../components/AddressModalItem/AddressModalItem";
import { BarLoader } from '../../components/BarLoader/BarLoader';

export const Addresses = () => {
    //HOOKS
    const { 
        addresses, 
        loadUserAddresses,
        setOpenAddressCreate,
        addressesLoading,
    } = useUserAddresses();

    //STATES
    const [loading, setLoading] = useState(false);

    //EFFECTS
    useEffect(() => {
        loadUserAddresses();
    }, []);

    return (
        <>
            <StoreHeader />
            <div className="addresses_app">
                <div className='body'>
                    <ProfileSideMenu />
                    <div className='address_app_content'>
                        <div className='address_container'>
                            <div className="header">
                                <h2>
                                    Direcciones guardadas
                                </h2>
                            </div>
                            <div className="address_body">
                                {
                                    (addressesLoading || loading) && (
                                        <div className='loader'>
                                            <BarLoader />
                                        </div>
                                    )
                                }
                                {
                                    addresses.map((address) => {
                                        return <AddressModalItem address={address} key={'address_profile_' + address.id} editMode={true} />
                                    })
                                }
                            </div>
                            <div className='footer'>
                                <div>

                                </div>
                                <div className='btns'>
                                    <button className='primary_btn' onClick={() => setOpenAddressCreate(true)}>
                                        Agregar nueva dirección
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </>
    );
}