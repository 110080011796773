import './store_header.css';
import logoAlternativo from '../../assets/img/png/logo_alternativo.png';
import { Link, useNavigate } from 'react-router-dom';
import { forwardRef, useEffect, useState } from "react";
import { useAuth } from '../../context/AuthContext';
import { useUserAddresses } from '../../context/UserAddressesContext';
import { HeaderUserDropdown } from '../HeaderUserDropdown/HeaderUserDropdown';
import { HeaderProductSearch } from '../HeaderProductSearch/HeaderProductSearch';
import { StoreHeaderMobile } from '../StoreHeaderMobile/StoreHeaderMobile';

export const StoreHeader = forwardRef((props, ref) => {
    const { user } = useAuth();
    const { 
        setOpenAddressesModal,
        selectedAddress,
        addresses,
    } = useUserAddresses();
    const navigate = useNavigate();

    const [addressText, setAddressText] = useState("Selecciona tu dirección");

    useEffect(() => {
        if (selectedAddress !== -1) {
            const address = addresses.find(address => address.id === selectedAddress) || null;
            setAddressText(address.streeType + " " + address.street + " " + address.houseNumber1 + " - " + address.houseNumber2);
        } else {
            setAddressText("Selecciona tu dirección");
        }
    }, [selectedAddress, addresses])

    return (
        <>
            <header className='store_header'>
                <div className='row1'>
                    <Link to={'/'} className='logo'>
                        <img src={logoAlternativo} alt='' />
                    </Link>
                    <HeaderProductSearch />
                </div>
                <div className='row2'>
                    <div className='col1'>
                        {/* <Link className='address_link' onClick={() => setOpenAddressesModal(true)}>
                            <i className="fa-solid fa-location-dot"></i>&nbsp; {addressText}
                        </Link> */}
                        <Link to={'/'}>
                            Inicio
                        </Link>
                        <Link to={'/catalogo'}>
                            Catalogo
                        </Link>
                        {/* <Link>
                            Categorias &nbsp;<i className="fa-solid fa-chevron-down"></i>
                        </Link> */}
                        {/* <Link>
                            Ofertas
                        </Link> */}
                    </div>
                    {/* <div className='col2'>
                        {
                            user === null && (
                                <>
                                    <Link to={'/registro'}>
                                        Crea tu cuenta
                                    </Link>
                                    <Link to={'/login'}>
                                        Ingresa
                                    </Link>
                                </>
                            )
                        }
                        {
                            user !== null && <HeaderUserDropdown />
                        }
                        
                        <Link to={'/carrito'}>
                            Carrito
                        </Link>
                    </div> */}
                </div>
            </header>
            <StoreHeaderMobile />
        </>
    );
});