import './purchases.css';
import { StoreHeader } from "../../components/StoreHeader/StoreHeader";
import { ProfileSideMenu } from '../../components/ProfileSideMenu/ProfileSideMenu';
import { useServer } from '../../context/ServerContext';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useEffect, useState } from 'react';
import { endpoints } from '../../env';
import { PurchaseItem } from '../../components/PurchaseItem/PurchaseItem';

export const Purchases = () => {
    //HOOKS
    const { getRequest } = useServer();

    //STATES
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    //FUNCTIONS
    const loadOrders = () => {
        setLoading(true);
        getRequest(endpoints.ecommerce_order_get_all, {}, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];
                setOrders(res.orders);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        loadOrders();
    }, []);

    return (
        <>
            <StoreHeader />
            <div className="purchases_app">
                <div className='body'>
                    <ProfileSideMenu />
                    <div className='purchases_app_content'>
                        {
                            loading && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }

                        {
                            orders.map((purchase) => {
                                return <PurchaseItem purchase={purchase} key={'purchase_item_' + purchase.id} />
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}