import { useEffect } from "react";
import { useServer } from "../../context/ServerContext";
import { ProductCard } from "../ProductCard/ProductCard";
import { backendEnv, endpoints } from "../../env";
import { useState } from "react";
import { useEcommerce } from "../../context/EcommerceContext";

export const ProductsList = () => {
    //HOOKS
    const { getRequest } = useServer();
    const { 
        sortingMode, 
        minPrice, 
        maxPrice, 
        selectedCategories,
        currentPage,
        setTotalProducts,
        perPage,
    } = useEcommerce();

    //STATES
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const loadProducts = () => {
        let data = {
            atze_website_id : backendEnv.websiteId, 
            sorting: sortingMode,
            min_price: parseInt(minPrice) * 100,
            per_page: perPage,
            page: currentPage
        };

        if (maxPrice !== null && maxPrice > 0) {
            data.max_price = parseInt(maxPrice) * 100;
        }

        if (selectedCategories.length > 0) {
            data.categories = selectedCategories;
        }

        setProducts([]);
        setLoading(true);
        getRequest(endpoints.ecommerce_product_list, data, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];
                setProducts(res.products);
                setTotalProducts(res.total);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        loadProducts();
    }, [sortingMode, minPrice, maxPrice, selectedCategories, currentPage]);

    return (
        <>
            <div className='product_container'>
                {
                    loading && (
                        <div className="loading_products">
                            <h2>Cargando productos...</h2>
                        </div>
                    )
                }
                
                {
                    products.map((product) => {
                        return <ProductCard product={product} key={'ecommerce_product_' + product.id} />
                    })
                }
            </div>
        </>
    );
}