import { useContext, useState } from "react";
import { LoginModal } from "../components/LoginModal/LoginModal";
import { createContext } from "react";

const AuthModalContext = createContext(undefined);

export const AuthModalProvider = ({children}) => {

    //STATES
    const [loginOpen, setLoginOpen] = useState(false);

    const values = {
        loginOpen,
        setLoginOpen,
    }

    return (
        <AuthModalContext.Provider
            value={values}
        >
            {loginOpen && <LoginModal />}
            {children}
        </AuthModalContext.Provider>
    )
}

export const useAuthModal = () => useContext(AuthModalContext);