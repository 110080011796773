import './shopping_cart.css';
import { StoreHeader } from "../../components/StoreHeader/StoreHeader";
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useState } from 'react';
import { ShoppingCartSummary } from '../../components/ShoppingCartSummary/ShoppingCartSummary';
import { ShoppingCartItem } from '../../components/ShoppingCartItem/ShoppingCartItem';
import { useCart } from '../../context/CartContext';
import { useNavigate } from 'react-router-dom';

export const ShoppingCart = () => {
    //HOOKS
    const { cart, loadingCart, getLocalePrice } = useCart();
    const navigate = useNavigate();
    
    //STATES
    const [loading, setLoading] = useState(false);

    return (
        <>
            <StoreHeader />
            <div className='shopping_cart_app'>
                <div className='shopping_cart_container'>
                    <div className='header'>
                        <h2>
                            Productos
                        </h2>
                    </div>
                    {
                        (loading || loadingCart) && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                    <div className='body'>
                        {
                            cart && cart.cartItems.map((item) => {
                                return <ShoppingCartItem item={item} key={'shopping_cart_item_' + item.token} />
                            })
                        }
                    </div>
                    <div className='footer'>
                        <div>
                            <button className='primary_btn' onClick={() => navigate('/catalogo', {replace: true})}>
                                Seguir comprando
                            </button>
                        </div>
                        <div>
                            <h2>
                                Total <span>{cart && getLocalePrice(cart.cartData.subtotal)}</span>
                            </h2>
                        </div>
                    </div>
                </div>

                <ShoppingCartSummary />
            </div>
        </>
    );
}