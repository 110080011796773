import './category_filter.css';
import { useEcommerce } from '../../context/EcommerceContext';

export const CategoryFilter = () => {
    const { 
        categories, 
        addCategory, 
        isCategoryAdded, 
        removeCategory, 
        selectedCategories, 
        setSelectedCategories,
        setCurrentPage,
    } = useEcommerce();
    
    const handleCategoryChange = (id) => {
        if (isCategoryAdded(id)) {
            removeCategory(id);
        } else {
            addCategory(id);
        }

        setCurrentPage(1);
    }

    const handleAllCategories = () => {
        if (selectedCategories.length > 0) {
            setSelectedCategories([]);
        }
    }

    return (
        <div className='category_filter filter_box'>
            <h2>
                Categorias
            </h2>
            <div className='input'>
                <input type='checkbox' checked={selectedCategories.length === 0} id={'ecommerce_category_input_all'} onChange={(e) => handleAllCategories()} />
                <label htmlFor={'ecommerce_category_input_all'} >
                    Todas las categorias
                </label>
            </div>
            {
                categories.map((category) => {
                    return (
                        <div className='input' key={'ecommerce_category_' + category.id}>
                            <input type='checkbox' checked={isCategoryAdded(category.id)} id={'ecommerce_category_input_' + category.id} onChange={(e) => handleCategoryChange(category.id)} />
                            <label htmlFor={'ecommerce_category_input_' + category.id} >
                                {category.name}
                            </label>
                        </div>
                    )
                })
            }
        </div>
    );
}