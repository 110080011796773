import './whatsapp_btn.css';

export function WhatsappBtn() {
    return (
        <>
            <a className="whatsapp" href="https://api.whatsapp.com/send?phone=573113739037" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-whatsapp whatsapp-icon"></i>
            </a>
        </>
    );
}