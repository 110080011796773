import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ServerProvider } from './context/ServerContext';
import { EcommerceProvider } from './context/EcommerceContext';
import { AuthProvider } from './context/AuthContext';
import { UserAddressProvider } from './context/UserAddressesContext';
import { GlobalClickProvider } from './context/GlobalClickContext';
import { CartProvider } from './context/CartContext';
import { PurchaseProccessProvider } from './context/PurchaseProccessContext';
import { AuthModalProvider } from './context/AuthModalContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  	<React.StrictMode>
		<BrowserRouter>
			<GlobalClickProvider>
				<AuthProvider>
					<ServerProvider>
						<AuthModalProvider>
							<UserAddressProvider>
								<EcommerceProvider>
									<CartProvider>
										<PurchaseProccessProvider>
											<App />
										</PurchaseProccessProvider>
									</CartProvider>
								</EcommerceProvider>
							</UserAddressProvider>
						</AuthModalProvider>
					</ServerProvider>
				</AuthProvider>
			</GlobalClickProvider>
		</BrowserRouter>
  	</React.StrictMode>
);
