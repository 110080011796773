import { useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";
import { AddressesModal } from "../components/AddressesModal/AddressesModal";
import { CreateAddressModal } from "../components/CreateAddressModal/CreateAddressModal";
import { DeleteAddressModal } from "../components/DeleteAddressModal/DeleteAddressModal";

const { createContext } = require("react");

const UserAddressesContext = createContext(undefined);

export const UserAddressProvider = ({children}) => {
    //HOOKS
    const { user } = useAuth();
    const { getRequest, postRequest } = useServer();

    //STATES
    const [openAddressesModal, setOpenAddressesModal] = useState(false);
    const [openAddressCreate, setOpenAddressCreate] = useState(false);
    const [openAddressDeleteModal, setOpenAddressDeleteModal] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [addressesLoading, setAddressesLoading] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(-1);
    const [addressToDelete, setAddressToDelete] = useState(-1);

    //FUNCTIONS
    const loadUserAddresses = () => {
        setAddressesLoading(true);
        getRequest(endpoints.user_address_list, {}, {}, (res) => {
            setAddressesLoading(false);
            if (res[0]) {
                res = res[1];
                setAddresses(res.addresses);
            }
        });
    }

    const saveSelectedAddress = (id, callback) => {
        const data = {
            default: 1,
            user_address_id: id,
        }

        postRequest(endpoints.user_address_update, data, {}, (res) => {
            callback(res);
        });
    }

    //EFFECTS
    useEffect(() => {
        if (user !== null) {
            loadUserAddresses();
        }
    }, [user]);

    useEffect(() => {
        const defAddress = addresses.find(address => address.default === 1) || null;

        if (defAddress !== null) {
            setSelectedAddress(defAddress.id);
        }
    }, [addresses]);

    const values = {
        loadUserAddresses,
        addresses,
        addressesLoading,
        openAddressesModal,
        setOpenAddressesModal,
        selectedAddress,
        setSelectedAddress,
        setOpenAddressCreate,
        saveSelectedAddress,
        setAddressToDelete,
        addressToDelete,
        setOpenAddressDeleteModal,
        openAddressDeleteModal,
    }

    return (
        <UserAddressesContext.Provider
            value={values}
        >
            {openAddressesModal && <AddressesModal /> }
            {openAddressDeleteModal && <DeleteAddressModal /> }
            {openAddressCreate && <CreateAddressModal /> }
            {children}
        </UserAddressesContext.Provider>
    );
}

export const useUserAddresses = () => useContext(UserAddressesContext);