import axios from "axios";
import { useContext } from "react";
import { backendEnv } from "../env";
import { useAuth } from "./AuthContext";

const { createContext } = require("react");

const ServerContext = createContext(undefined);

export const ServerProvider = ({children}) => {
    //HOOKS
    const { getToken } = useAuth();

    const api = axios.create({
        baseURL: backendEnv.url,
        withCredentials: true,
    });

    const postRequest = (url, data, options, callback) => {
        api.post(url, data, {headers: {'Authorization': 'Bearer ' + getToken()}}).then(res => {
            callback([true, res.data]);
        }).catch((res) => {
            if (options.handleServerError === undefined || !options.handleServerError) {
                //HANDLE GLOBAL SERVER ERROR
            }

            callback([false, res]);
        });
    }

    const getRequest = (url, data, options, callback) => {
        let requestOptions =  {
            params: data,
            headers: {'Authorization': 'Bearer ' + getToken()},
        };

        if (options.withCredentials !== undefined || options.withCredentials !== null) {
            requestOptions.withCredentials = options.withCredentials;
        }

        api.get(url, requestOptions).then(res => {
            callback([true, res.data]);
        }).catch((res) => {
            if (options.handleServerError === undefined || !options.handleServerError) {
                //HANDLE GLOBAL SERVER ERROR
            }

            callback([false, res]);
        });
    }

    const value = {
        postRequest,
        getRequest,
    }

    return (
        <ServerContext.Provider
            value={value}
        >
            {children}
        </ServerContext.Provider>
    );
}

export const useServer = () => useContext(ServerContext);