import { useState } from "react";
import { BarLoader } from "../BarLoader/BarLoader";
import { useServer } from "../../context/ServerContext";
import { endpoints } from "../../env";

export const ProfilePasswordChange = () => {
    //HOOKS
    const { postRequest } = useServer();

    //STATES
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    //FUNCTIONS
    const updatePassword = () => {
        if (loading) {
            return;
        }

        if (password.trim() === "") {
            setErrorMsg("Ingresa la nueva contraseña");
            return;
        }

        if (password !== password2) {
            setErrorMsg("Las contraseñas no coinciden");
            return;
        }

        setErrorMsg("");
        setLoading(true);

        const data = {
            password
        }

        postRequest(endpoints.user_update, data, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                setPassword("");
                setPassword2("");
                alert("Contraseña cambiada exitosamente.");
            } else {
                setErrorMsg("Error del servidor, intenta de nuevo en un momento.");
            }
        });
    }

    return (
        <div className='profile_container'>
            <div className='header'>
                <h2>
                    Cambiar contraseña
                </h2>
            </div>
            <div className='profile_body'>
                {
                    errorMsg !== "" && (
                        <div className="error_msg">
                            {errorMsg}
                        </div>
                    )
                }
                <div className='fancy_input_group'>
                    <div className='fancy_input'>
                        <label>Nueva contraseña</label>
                        <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>
                <div className='fancy_input_group'>
                    <div className='fancy_input'>
                        <label>Repite la contraseña</label>
                        <input type='password' value={password2} onChange={(e) => setPassword2(e.target.value)} />
                    </div>
                </div>
                {
                    loading && (
                        <div className="loader">
                            <BarLoader />
                        </div>
                    )
                }
                <div className='actions'>
                    <div>

                    </div>
                    <div className='btns'>
                        <button className='primary_btn' disabled={loading} onClick={updatePassword}>
                            Cambiar contraseña
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}