import { createContext } from "react";
import { useContext } from "react";
import { useServer } from "./ServerContext";
import { useEffect } from "react";
import { endpoints } from "../env";
import { useState } from "react";
import { useAuth } from "./AuthContext";

const CartContext = createContext(undefined);

export const CartProvider = ({children}) => {
    //HOOKS
    const { getRequest, postRequest } = useServer();
    const { user } = useAuth();

    //STATES
    const [cart, setCart] = useState(null);
    const [loadingCart, setLoadingCart] = useState(false);

    //FUNCTIONS
    const loadCart = () => {
        setLoadingCart(true);
        getRequest(endpoints.ecommerce_cart_get, {}, {}, (res) => {
            setLoadingCart(false);

            if (res[0]) {
                res = res[1];
                setCart(res.cart);
            }
        });
    }

    const updateItem = (token, params, callback) => {
        const data = {
            token,
            ...params
        }

        postRequest(endpoints.ecommerce_cart_item_update, data, {}, (res) => {
            callback(res);
            if (res[0]) {
                loadCart();
            }
        });
    }

    const deleteItem = (token, callback) => {
        const data = {
            token
        }

        postRequest(endpoints.ecommerce_cart_item_delete, data, {}, (res) => {
            callback(res);
            if (res[0]) {
                loadCart();
            }
        });
    }

    const getLocalePrice = (price) => {
        return (price / 100).toLocaleString("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }

    //EFFECTS
    useEffect(() => {
        if (user !== null) {
            loadCart();
        }
    }, [user]);

    const values = {
        loadCart,
        loadingCart,
        cart,
        updateItem,
        deleteItem,
        getLocalePrice,
    }

    return (
        <CartContext.Provider
            value={values}
        >
            {children}
        </CartContext.Provider>
    );
}

export const useCart = () => useContext(CartContext);