import { Route, Routes, useLocation } from "react-router-dom";
import { Index } from "./pages/index/Index";
import { Lights } from "./pages/lights/Lights";
import { useEffect } from "react";
import { Store } from "./pages/Store/Store";
import { Terms } from "./pages/legal_pages/Terms";
import { PrivacyPolicy } from "./pages/legal_pages/PrivacyPolicy";
import { CookiesPolicy } from "./pages/legal_pages/CookiesPolicy";
import { ReturnsPolicy } from "./pages/legal_pages/ReturnsPolicy";
import { SingleProduct } from "./pages/SingleProduct/SingleProduct";
import { Login } from "./pages/login/Login";
import { Register } from "./pages/register/Register";
import { Profile } from "./pages/profile/profile";
import { ProtectedRoutes } from "./components/ProtectedRoutes/ProtectedRoutes";
import { Addresses } from "./pages/adresses/Addresses";
import { ShoppingCart } from "./pages/shopping_cart/ShoppingCart";
import { ProductAdded } from "./pages/product_added/ProductAdded";
import { PurchaseDeliverySelect } from "./pages/purchase_delivery_select/PurchaseDeliverySelect";
import { PurchasePayment } from "./pages/purchase_payment/PurchasePayment";
import { PurchasePaymentVerification } from "./pages/purchase_payment_verification/PurchasePaymentVerification";
import { Purchases } from "./pages/purchases/Purchases";
import { Purchase } from "./pages/purchase/Purchase";

function App() {

	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

  	return (
		<>
			<Routes>
				<Route path="/" element={<Index />} />
				<Route path="/luces-led" element={<Lights />} />
				<Route path="/catalogo" element={<Store />} />
				<Route path="/tienda/product/:productId" element={<SingleProduct />} />
				<Route path="/terminos-y-condiciones" element={<Terms />} />
				<Route path="/politicas-de-privacidad" element={<PrivacyPolicy />} />
				<Route path="/politicas-de-cookies" element={<CookiesPolicy />} />
				<Route path="/politicas-cambios-devoluciones" element={<ReturnsPolicy />} />
				<Route path="/login" element={<Login />} />
				<Route path="/registro" element={<Register />} />
			</Routes>

			<ProtectedRoutes>
				<Route path="/perfil" element={<Profile />} />
				<Route path="/direcciones" element={<Addresses />} />
				<Route path="/carrito" element={<ShoppingCart />} />
				<Route path="/compras" element={<Purchases />} />
				<Route path="/product/added" element={<ProductAdded />} />
				<Route path="/compra/envio" element={<PurchaseDeliverySelect />} />
				<Route path="/compra/pago" element={<PurchasePayment />} />
				<Route path="/compra/:orderId" element={<Purchase />} />
				<Route path="/compra/:orderId/pago/verificacion" element={<PurchasePaymentVerification />} />
			</ProtectedRoutes>
		</>
  	);
}

export default App;
