import './header.css';
import logo from '../../assets/img/png/logo.png';
import logoAlternativo from '../../assets/img/png/logo_alternativo.png';
import {useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { forwardRef, useState } from "react";

export const Header = forwardRef((props, ref) => {
    const [responsiveMenuActive, setResponsiveMenuActive] = useState(false);

    const handleResponsiveLink = () => {
        setResponsiveMenuActive(false);
    }

    return (
        <>
            <header className='webpage_header'>
                <div className='main_links'>
                    <Link to={'/'}>
                        Inicio
                    </Link>
                    <Link to={'/#nosotros'}>
                        Nosotros
                    </Link>
                    <Link to={'/#servicios'}>
                        Servicios
                    </Link>
                    <Link to={'/luces-led'}>
                        Luces LED
                    </Link>
                </div>
                <Link to={'/'} className='logo'>
                    <img src={logoAlternativo} alt='' />
                </Link>
                <div className='second_links'>
                    <Link to={'/catalogo'}>
                        Catalago
                    </Link>
                    {/* <Link to={'/catalogo'}>
                        Sigue tu pedido
                    </Link> */}
                    <div className='whatsapp_btn_container'>
                        <Link className='whatsapp_btn' to='https://api.whatsapp.com/send?phone=573113739037' target='_blank'>
                            <i className="fa-brands fa-whatsapp"></i>
                            ESCRÍBENOS
                        </Link>
                    </div>
                </div>
            </header>
            <div className={responsiveMenuActive ? 'header_backdrop active' : 'header_backdrop'}>

            </div>
            <header className={responsiveMenuActive ? 'webpage_header_responsive active' : 'webpage_header_responsive'}>
                <button className='close' onClick={() => setResponsiveMenuActive(prev => !prev)}>
                    <div id="nav-icon1" className={responsiveMenuActive? 'open' : ''}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <Link to={'/'} className='logo' onClick={handleResponsiveLink}>
                    <img src={logoAlternativo} alt='' />
                </Link>
                <Link to={'/'} className='item' onClick={handleResponsiveLink}>
                    Inicio
                </Link>
                <Link to={'/#nosotros'} className='item' onClick={handleResponsiveLink}>
                    Nosotros
                </Link>
                <Link to={'/#servicios'} className='item' onClick={handleResponsiveLink}>
                    Servicios
                </Link>
                <Link to={'/luces-led'} className='item' onClick={handleResponsiveLink}>
                    Luces LED
                </Link>
                <Link to={'/catalogo'} className='item' onClick={handleResponsiveLink}>
                    Catalogo
                </Link>

                <div className='social_media'>
                    <Link to='https://www.facebook.com/people/Only-Car-Manizales/100084187652986/' target='_blank'>
                        <i className="fa-brands fa-facebook-f"></i>
                    </Link>
                    <Link to='https://www.instagram.com/onlycar_manizales/' target='_blank'>
                        <i className="fa-brands fa-instagram"></i>
                    </Link>
                    <Link to='https://www.google.com/search?gs_ssp=eJzj4tVP1zc0LIw3MjQqMTIxYLRSNaiwSDUxN0szNDNMS0tJNDA3tzKoSE1NNDVLTEpMNTNKTjU3SvQSys_LqVRITixSyE3My6xKzEktBgACrBZ0&q=only+car+manizales&rlz=1C1CHBD_enCO1068CO1068&oq=Only+Car+M&aqs=chrome.1.69i57j46i175i199i512j0i512j0i22i30l2j69i60l3.2703j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8e476f161ffda077:0xeea56abae62ce72a,1,,,,' target='_blank'>
                        <i className="fa-solid fa-store"></i>
                    </Link>
                    <Link to='https://www.tiktok.com/@onlycar_manizales' target='_blank'>
                        <i className="fa-brands fa-tiktok"></i>
                    </Link>
                </div>
            </header>
        </>
    );
});