import './store_header_mobile.css';
import Logo from '../../assets/img/png/logo_alternativo.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { HeaderProductSearch } from '../HeaderProductSearch/HeaderProductSearch';

export const StoreHeaderMobile = () => {
    //HOOKS
    const navigate = useNavigate();
    const { user, logout } = useAuth();

    //STATES
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <>
            <div className='responsive_store_header_logo'>
                <Link className='logo' to={'/'}>
                    <img src={Logo} alt='' />
                </Link>
                <Link className='link' to={'/'}>
                    Inicio
                </Link>
            </div>
            <header className="store_header_mobile">
                {/* <div className='cart' onClick={() => navigate('/carrito', {replace: true})}>
                    <i className="fa-solid fa-cart-shopping"></i>
                </div> */}
                {/* <div className='search_container'>
                    <input type='text' placeholder='Buscar producto' />
                    <div className='btn'>
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                </div> */}
                <HeaderProductSearch />
            </header>
            <div className={menuOpen ? "store_header_backdrop active" : "store_header_backdrop"}>

            </div>
            <div className={menuOpen ? 'store_header_mobile_menu active' : 'store_header_mobile_menu'}>
                <button className='close' onClick={() => setMenuOpen((prev) => !prev)}>
                    <div id="nav-icon1" className={menuOpen ? "open" : ""}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <Link className='logo' to={'/catalogo'}>
                    <img src={Logo} alt='' />
                </Link>
                {/* {
                    user === null && (
                        <>
                            <Link className='item' to={'/login'}>
                                Ingresa
                            </Link>
                            <Link className='item' to={'/registro'}>
                                Crea tu cuenta
                            </Link> 
                        </>
                    )
                } */}
                {/* {
                    user !== null && (
                        <>
                            <Link className='item' to={'/perfil'}>
                                {user.name}
                            </Link>
                            <Link className='item' to={'/perfil'}>
                                Mi perfil
                            </Link>
                            <Link className='item' to={'/direcciones'}>
                                Direcciones guardadas
                            </Link>
                            <Link className='item' to={'/compras'}>
                                Mis compras
                            </Link>
                            <Link className='item' to={'/catalogo'}>
                                Tienda virtual
                            </Link>
                            <Link className='item' onClick={() => logout()}>
                                Cerrar sesión
                            </Link>
                        </>
                    )
                } */}
                <Link className='item' to={'/'}>
                    Inicio
                </Link>
                <Link className='item' to={'/catalogo'}>
                    Catálogo
                </Link>
                <Link className='item' to={'/#nosotros'}>
                    Nosotros
                </Link>
                <Link className='item' to={'/#servicios'}>
                    Servicios
                </Link>
                <Link className='item' to={'/luces-led'}>
                    Luces LED
                </Link>
            </div>
        </>
    );
}