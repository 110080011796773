import './footer.css';
import logo from '../../assets/img/png/logo.png';
import logoAlternativo from '../../assets/img/png/logo_alternativo.png';
import audi from '../../assets/img/png/audi.png';
import { Link } from 'react-router-dom';
import { forwardRef } from "react";

export const Footer = forwardRef((props, ref) => {
    return (
        <footer className='webpage_footer'>
            <div className='main_footer'>
                <div className='logo'>
                    <Link>
                        <img src={logoAlternativo} alt='' />
                    </Link>
                </div>
                <div></div>
                <div className='map'>
                    <div className='map_container'>
                        <iframe src="https://maps.google.com/maps?width=700&amp;height=200&amp;hl=en&amp;q=Cra.%2025%20%2346-54%2C%20Manizales%2C%20Caldas+(Only%20Car)&amp;ie=UTF8&amp;t=&amp;z=17&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                </div>
                <div className='content'>
                    <Link to='tel:3175425711'>
                        <i className="fa-brands fa-whatsapp"></i>
                        317 5425711 - 311 3739037
                    </Link>
                    <Link to='https://maps.app.goo.gl/DwXn2MnKUJuwm7uy5' target='_blank'>
                        <i className="fa-solid fa-location-dot"></i>
                        Cra. 25 #46-54, Manizales, Caldas, Colombia
                    </Link>
                    <Link>
                        <i className="fa-solid fa-clock"></i>
                        Lunes a Sabado 8:30 AM a 6:00 PM
                    </Link>
                    <Link>
                        <i className="fa-solid fa-toolbox"></i>
                        Servicio eléctrico automotriz
                    </Link>
                    <div className='social'>
                        <Link to='https://www.facebook.com/people/Only-Car-Manizales/100084187652986/' target='_blank'>
                            <i className="fa-brands fa-facebook-f"></i>
                        </Link>
                        <Link to='https://www.instagram.com/onlycar_manizales/' target='_blank'>
                            <i className="fa-brands fa-instagram"></i>
                        </Link>
                        <Link to='https://www.google.com/search?gs_ssp=eJzj4tVP1zc0LIw3MjQqMTIxYLRSNaiwSDUxN0szNDNMS0tJNDA3tzKoSE1NNDVLTEpMNTNKTjU3SvQSys_LqVRITixSyE3My6xKzEktBgACrBZ0&q=only+car+manizales&rlz=1C1CHBD_enCO1068CO1068&oq=Only+Car+M&aqs=chrome.1.69i57j46i175i199i512j0i512j0i22i30l2j69i60l3.2703j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8e476f161ffda077:0xeea56abae62ce72a,1,,,,' target='_blank'>
                            <i className="fa-solid fa-store"></i>
                        </Link>
                        <Link to='https://www.tiktok.com/@onlycar_manizales' target='_blank'>
                            <i className="fa-brands fa-tiktok"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='footer_nav'>
                <div className='nav_group'>
                    <h2>
                        Recursos
                    </h2>
                    <Link to={'/terminos-y-condiciones'}>
                        Términos y condiciones
                    </Link>
                    <Link to={'/politicas-de-privacidad'}>
                        Política de privacidad
                    </Link>
                    <Link to={'/politicas-de-cookies'}>
                        Política de cookies
                    </Link>
                    {/* <Link to={'/politicas-cambios-devoluciones'}>
                        Política de cambios <br /> y devoluciones
                    </Link> */}
                    {/* <Link to={'/compras'}>
                        Estado de mi pedido
                    </Link> */}
                    {/* <Link>
                        Quiero ser distribuidor
                    </Link> */}
                </div>
                <div className='nav_group'>
                    <h2>
                        Contáctenos
                    </h2>
                    <Link to='tel:3175425711'>
                        Telefono: 317 5425711
                    </Link>
                    <Link to='tel:3113739037'>
                        Telefono: 311 3739037
                    </Link>
                    <Link to='mailto:info@onlycarmanizales.com'>
                        Correo: info@onlycarmanizales.com
                    </Link>
                </div>
                <div className='nav_group'>
                    <h2>
                        Mapa del sitio
                    </h2>
                    <Link to={'/'}>
                        Inicio
                    </Link>
                    <Link to={'/'}>
                        Sobre nosotros
                    </Link>
                    <Link to={'/'}>
                        Servicios
                    </Link>
                    <Link to={'/catalogo'}>
                        Catálogo
                    </Link>
                    {/* <Link to={'/compras'}>
                        Sigue tu pedido
                    </Link> */}
                </div>
                <div className='nav_group newsletter'>
                    {/* <h2>
                        Suscríbete
                    </h2>
                    <input type='text' placeholder='Tu correo electrónico' />
                    <span className='suscribe_disclaimer'>
                        Al suscribirte aceptas los &nbsp;
                        <Link to={'/terminos-y-condiciones'}>
                            terminos y condiciones
                        </Link> &nbsp;
                        de nuestra newsletter
                    </span>
                    <button className='suscribe_btn'>
                        Suscribete
                    </button> */}
                </div>
            </div>
            <div className='copyright_bar'>
                <Link to={'/'}>
                    Only Car Lujos y Accesorios © 2023
                </Link>
            </div>
            <img className='car' src={audi} alt='' />
        </footer>
    );
});