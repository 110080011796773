import React from "react";
import { Routes } from "react-router-dom";
import { ProtectRoute } from "./ProtectRoute";

export const ProtectedRoutes = ({children}) => {
    return (
        <>
            <Routes>
                {children}
            </Routes>

            {
                React.Children.map(children, child => {
                    const { path } = child.props;
                    return <ProtectRoute path={path} key={"protected_route_" + path} />
                })
            }
        </>
    );
}