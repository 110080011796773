import './purchase_item.css';
import { useCart } from '../../context/CartContext';
import { useEffect, useState } from 'react';
import imagePlaceholder from '../../assets/img/jpg/product_placeholder.jpg';
import { backendEnv } from '../../env';
import { useNavigate } from 'react-router-dom';

export const PurchaseItem = ({purchase}) => {
    //HOOKS
    const { getLocalePrice } = useCart();
    const receivedDate = new Date(purchase.created_at);
    const navigate = useNavigate();

    const options = {
        timeZone: "America/Bogota",
        hour12: true, // Use 24-hour time format
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };

    const colombiaTime = receivedDate.toLocaleString("es-CO", options);

    //STATES
    const [orderState, setOrderState] = useState("Procesando");

    useEffect(() => {
        switch (purchase.state) {
            case 0:
                setOrderState("Procesando");
                break;
            case 1:
                setOrderState("Pendiente de pago");
                break;
            case 2:
                setOrderState("Verificando pago");
                break;
            case 3:
                setOrderState("Pago rechazado");
                break;
            case 4:
                setOrderState("Pago exitoso");
                break;
            case 5:
                setOrderState("Preparando el pedido");
                break;
            case 6:
                setOrderState("Enviando pedido");
                break;
            case 7:
                setOrderState("Compra entregada");
                break;
            default:
                setOrderState("Procesando");
                break;
        }
    }, [purchase]);

    return (
        <div className="purchase_item">
            <div className='header'>
                <h2>
                    Pedido #{purchase.id} &nbsp;
                    {colombiaTime}
                </h2>
                <button className='primary_btn' onClick={() => navigate('/compra/' + purchase.id, {replace: true})}>
                    Ver compra
                </button>
            </div>
            <div className='body_purchases'>
                {
                    purchase.items.map((item) => {
                        return (
                            <div className='purchase_product_item'>
                                <div className='pic'>
                                    <img src={item.image !== "" ? backendEnv.url + item.image : imagePlaceholder} alt='' />
                                </div>
                                <div className='info'>
                                    <h2>
                                        {item.name}
                                    </h2>
                                    <p>
                                        Cantidad: {item.qty}
                                    </p>
                                </div>
                                <div className='total'>
                                    {getLocalePrice(item.total)}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='footer'>
                <div>
                    <div className='status'>
                        Estado: {orderState}
                    </div>
                </div>
                <h2>Total {getLocalePrice(purchase.total)}</h2>
            </div>
        </div>
    );
}