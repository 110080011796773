import './login_modal.css';
import Logo from '../../assets/img/png/logo_alternativo.png';
import { useState } from 'react';
import { useAuthModal } from '../../context/AuthModalContext';
import { BarLoader } from '../BarLoader/BarLoader';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export const LoginModal = () => {
    //HOOKS
    const { setLoginOpen } = useAuthModal();
    const { login } = useAuth();
    const navigate = useNavigate();

    //STATES
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const postLogin = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setErrorMsg("");

        login(email, pwd, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];

                if (res.success) {
                    setLoginOpen(false);
                } else {
                    if (res.error_code === "wrong_credentials") {
                        setErrorMsg("Correo o contraseña incorrectos.");
                    } else if (res.error_code === "validator_exception") {
                        setErrorMsg("Debes ingresar el correo y contraseña");
                    }
                }
            } else {
                setErrorMsg("Error del servidor, intenta de nuevo en un momento.")
            }
        });
    }

    const goToRegister = () => {
        setLoginOpen(false);
        navigate('/registro', {replace: true});
    }

    return (
        <>
            <div className='login_modal'>
                <div className='form'>
                    <button className='close' onClick={() => setLoginOpen(false)} disabled={loading}>
                        <i className="fas fa-times"></i>
                    </button>
                    <img src={Logo} alt='' />
                    <h2>
                        Ingresa tu correo y contraseña
                    </h2>
                    {
                        errorMsg !== "" && (
                            <div className='error_msg'>
                                {errorMsg}
                            </div>
                        )
                    }
                    <div className='fancy_input'>
                        <label>Correo</label>
                        <input type='text' placeholder='ejemplo@correo.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='fancy_input'>
                        <label>Contraseña</label>
                        <input type='password' value={pwd} onChange={(e) => setPwd(e.target.value)} />
                    </div>
                    {
                        loading && (
                            <div className='loading'>
                                <BarLoader />
                            </div>
                        )
                    }
                    <button className='primary_btn' disabled={loading} onClick={postLogin}>
                        Iniciar sesión
                    </button>
                    <button className='secundary_btn' disabled={loading} onClick={goToRegister}>
                        Crear cuenta
                    </button>
                </div>
            </div>
        </>
    );
}