import './addresses_modal.css';
import { BarLoader } from '../BarLoader/BarLoader';
import { Link } from 'react-router-dom';
import { useUserAddresses } from '../../context/UserAddressesContext';
import { AddressModalItem } from '../AddressModalItem/AddressModalItem';
import { useEffect, useState } from 'react';

export const AddressesModal = () => {
    //HOOKS
    const { 
        addressesLoading, 
        addresses,
        setOpenAddressesModal,
        selectedAddress,
        setSelectedAddress,
        loadUserAddresses,
        setOpenAddressCreate,
        saveSelectedAddress,
    } = useUserAddresses();

    //STATES
    const [initialSelected, setInitialSelected] = useState(null);

    //FUNCTIONS
    const cancel = () => {
        setSelectedAddress(initialSelected);
        setOpenAddressesModal(false);
    }

    const save = () => {
        setOpenAddressesModal(false);
        saveSelectedAddress(selectedAddress, (res) => {
        });
    }
    
    //EFFECTS
    useEffect(() => {
        setInitialSelected(selectedAddress);
        loadUserAddresses();
    }, []);

    return (
        <>
            <div className="addresses_modal_container">
                <div className='form_container'>
                    <h2 className='title'>
                        Selecciona donde llegaran tus compras
                    </h2>
                    <div className='addresses_container'>
                        <div className='body'>
                            {
                                addressesLoading && <BarLoader />
                            }
                            {
                                addresses.map((address) => {
                                    return (
                                        <AddressModalItem address={address} key={'addresses_modal_address_' + address.id} />
                                    )
                                })
                            }
                        </div>
                        <div className='footer'>
                            <Link to={'/direcciones'} onClick={cancel}>
                                Editar direcciones registradas
                            </Link>
                        </div>
                    </div>
                    <button className='primary_btn new_address_btn' onClick={() => setOpenAddressCreate(true)}>
                        Agregar nueva dirección
                    </button>
                    <div className='actions'>
                        <div>
                            
                        </div>
                        <div className='btns'>
                            <button className='secundary_btn' onClick={cancel}>
                                Cancelar
                            </button>
                            <button className='primary_btn' onClick={save}>
                                Guardar cambios
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};