import '../login/login.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/img/png/logo_alternativo.png';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useServer } from '../../context/ServerContext';
import { backendEnv, endpoints } from '../../env';

export const Register = () => {
    //HOOKS
    const { setToken, isAuth, login } = useAuth();
    const { postRequest } = useServer();
    const navigate = useNavigate();

    //STATES
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [pwd, setPwd] = useState("");
    const [pwd2, setPwd2] = useState("");

    //FUNCTIONS
    const postRegister = () => {
        if (loading) {
            return;
        }

        if (pwd !== pwd2) {
            setErrorMsg("Las contraseñas no coinciden");
            setLoading(false);
            return;
        }

        setLoading(true);
        setErrorMsg("");

        const data = {
            atze_website_id: backendEnv.websiteId,
            name,
            lastname,
            email,
            password : pwd,
        }

        postRequest(endpoints.user_register, data, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];

                if (res.success) {
                    setToken(res.token);
                    login(email, pwd, (res) => {});
                    navigate('/catalogo', {replace: true});
                } else {
                    if (res.error_code === "validator_exception") {
                        setErrorMsg("Se requieren todos los campos");
                    } else if (res.error_code === "email_used") {
                        setErrorMsg("Ya existe una cuenta registrada con el correo ingresado.");
                    }
                }
            } else {
                setErrorMsg("Error del servidor, intenta de nuevo en un momento");
            }
        });
    }

    const goToLogin = () => {
        navigate('/login', {replace: true});
    }

    //EFFECTS
    useEffect(() => {
        if (isAuth !== null) {
            if (isAuth) {
                navigate('/catalogo', {replace: true});
            }
        }
    }, [isAuth]);


    return (
        <>
            <div className='login_app_body'>
                <div className='header'>
                    <div className='body'>
                        <Link className='logo'>
                            <img src={logo} alt='' />
                        </Link>
                    </div>
                </div>
                <div className='form'>
                    <h1>
                        Ingresa los datos para crear tu cuenta
                    </h1>
                    {
                        errorMsg !== "" && (
                            <div className='error_msg'>
                                {errorMsg}
                            </div>
                        )
                    }
                    <div className='fancy_input'>
                        <label>Correo</label>
                        <input type='text' placeholder='ejemplo@correo.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='fancy_input'>
                        <label>Nombre</label>
                        <input type='text' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='fancy_input'>
                        <label>Apellido</label>
                        <input type='text' value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </div>
                    <div className='fancy_input'>
                        <label>Contraseña</label>
                        <input type='password' value={pwd} onChange={(e) => setPwd(e.target.value)} />
                    </div>
                    <div className='fancy_input'>
                        <label>Repite la contraseña</label>
                        <input type='password' value={pwd2} onChange={(e) => setPwd2(e.target.value)} />
                    </div>

                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                    
                    <button className='login_btn' onClick={postRegister} disabled={loading}>
                        Crear cuenta
                    </button>
                    <button className='signup_btn' onClick={goToLogin} disabled={loading}>
                        Iniciar sesión
                    </button>
                </div>
            </div>
        </>
    );
}