import './purchase_delivery_select.css';
import { StoreHeader } from "../../components/StoreHeader/StoreHeader";
import { useUserAddresses } from '../../context/UserAddressesContext';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import { usePurchaseProccess } from '../../context/PurchaseProccessContext';

export const PurchaseDeliverySelect = () => {
    //HOOKS
    const { 
        setOpenAddressesModal,
        selectedAddress,
        addresses,
    } = useUserAddresses();

    const { deliveryType, setDeliveryType } = usePurchaseProccess();
    const { cart, getLocalePrice } = useCart();
    const navigate = useNavigate();

    //STATES
    const [addressText, setAddressText] = useState("Selecciona tu dirección");
    const [msgError, setMsgError] = useState("");

    //EFFECTS
    useEffect(() => {
        if (selectedAddress !== -1) {
            const address = addresses.find(address => address.id === selectedAddress) || null;
            setAddressText(address.streeType + " " + address.street + " " + address.houseNumber1 + " - " + address.houseNumber2 + " / " + address.city + " - " + address.state);
        } else {
            setAddressText("Selecciona tu dirección");
        }
    }, [selectedAddress, addresses])

    return (
        <>
            <StoreHeader />
            <div className="purchase_delivery_app">
                <div className='delivery_method_container'>
                    <h2 className='title'>
                        Elige la forma de envío
                    </h2>
                    {
                        msgError !== "" && (
                            <div className='error_msg'>
                                {msgError}
                            </div>
                        )
                    }
                    <div className='method_container'>
                        <div className='header' onClick={() => setDeliveryType(1)}>
                            <input type='radio' checked={deliveryType === 1} onChange={() => setDeliveryType(1)} />
                            <h2>
                                Enviar a domicilio
                            </h2>
                            <div className='price'>
                                {cart && getLocalePrice(cart.cartData.deliveryPrice)}
                            </div>
                        </div>
                        <div className='body'>
                            <p>
                                {addressText}
                            </p>
                        </div>
                        <div className='footer'>
                            <button className='primary_btn' onClick={() => setOpenAddressesModal(true)}>
                                Cambiar dirección
                            </button>
                        </div>
                    </div>

                    <div className='method_container'>
                        <div className='header' onClick={() => setDeliveryType(0)}>
                            <input type='radio' checked={deliveryType === 0} onChange={() => setDeliveryType(0)} />
                            <h2>
                                Recoger en la tienda (Manizales)
                            </h2>
                            <div className='price'>
                                GRATIS
                            </div>
                        </div>
                        <div className='body'>
                            <p>
                                Cra. 25 #46-54, Manizales, Caldas
                            </p>
                        </div>
                        <div className='footer'>
                            <Link target='_blank' to='https://maps.app.goo.gl/QDo4EQwR1FWEqDwL8' className='primary_btn'>
                                Ver ubicación en el mapa
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='purchase_summary_container'>
                    <div className='summary_container'>
                        <div className='header'>
                            <h2>
                                Resumen de la compra
                            </h2>
                        </div>
                        <div className='body'>
                            <div className='item'>
                                <h2>
                                    Productos ({ cart && cart.cartItems.length})
                                </h2>
                                <span>
                                    { cart && getLocalePrice(cart.cartData.subtotal)}
                                </span>
                            </div>
                            <div className='item'>
                                <h2>
                                    Envío
                                </h2>
                                <span>
                                    {
                                        deliveryType === 1 && (
                                            <>
                                                { cart && getLocalePrice(cart.cartData.deliveryPrice)}
                                            </>
                                        )
                                    }
                                    {
                                        deliveryType === 0 && (
                                            <>
                                                { cart && getLocalePrice(0)}
                                            </>
                                        )
                                    }
                                </span>
                            </div>
                            <div className='item'>
                                <h2>
                                    Total
                                </h2>
                                <span>
                                    {
                                        deliveryType === 1 && (
                                            <>
                                                { cart && getLocalePrice(cart.cartData.total)}
                                            </>
                                        )
                                    }
                                    {
                                        deliveryType === 0 && (
                                            <>
                                                { cart && getLocalePrice(cart.cartData.total - cart.cartData.deliveryPrice)}
                                            </>
                                        )
                                    }
                                </span>
                            </div>
                            <button className='primary_btn' onClick={() => navigate('/compra/pago')}>
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}