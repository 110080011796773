import './shopping_cart_item.css';
import imagePlaceholder from '../../assets/img/jpg/product_placeholder.jpg';
import { QtyInput } from '../QtyInput/QtyInput';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { backendEnv } from '../../env';
import { useRef } from 'react';
import { useCart } from '../../context/CartContext';

export const ShoppingCartItem = ({item}) => {
    //HOOKS
    const { updateItem, deleteItem, getLocalePrice } = useCart();

    //REFS
    const qtyRef = useRef();

    //STATES
    const [image, setImage] = useState(imagePlaceholder);

    //FUNCTIONS
    const qtyChange = (value) => {
        if (value === item.qty) {
            return;
        }

        qtyRef.current.setLoading(true);
        updateItem(item.token, {qty: value}, (res) => {
            qtyRef.current.setLoading(false);
        });
    }

    const postDelete = () => {
        deleteItem(item.token, (res) => {

        });
    }

    const calculatePercentageDiscount = (regularPrice, salePrice) => {
        const discountAmount = regularPrice - salePrice;
        const percentageDiscount = (discountAmount / regularPrice) * 100;
        const roundedPercentageDiscount = Math.round(percentageDiscount);
      
        return roundedPercentageDiscount;
    }
    
    //EFFECTS
    useEffect(() => {
        if (item.productData.image) {
            setImage(backendEnv.url + item.productData.image.path);
        }
    }, [item]);

    return (
        <div className='shopping_cart_item'>
            <div className='pic'>
                <img src={image} alt='' />
            </div>
            <div className='info'>
                <h2>
                    {item.productData.name}
                </h2>
                <div>
                    <Link onClick={postDelete}>
                        Eliminar
                    </Link>
                </div>
            </div>
            <QtyInput ref={qtyRef} initalValue={item.qty} callback={qtyChange} />
            <div className='price'>
                {
                    item.productData.saleActive === 1 && ( 
                        <div className='discount'>
                            <p className='percentaje_discount'>
                                -{calculatePercentageDiscount(item.productData.regularPrice, item.productData.salePrice)}%
                            </p>
                            <span className='old_price'>
                               
                            </span>
                        </div>
                    )
                }
                <h2>
                    {getLocalePrice(item.total)}
                </h2>
            </div>
        </div>
    );
}