import './delete_address_modal.css';
import { BarLoader } from '../BarLoader/BarLoader';
import { useState } from 'react';
import { useUserAddresses } from '../../context/UserAddressesContext';
import { useServer } from '../../context/ServerContext';
import { endpoints } from '../../env';

export const DeleteAddressModal = () => {
    //HOOKS
    const {
        setOpenAddressDeleteModal,
        setAddressToDelete,
        addressToDelete,
        selectedAddress,
        setSelectedAddress,
        loadUserAddresses,
    } = useUserAddresses();

    const { postRequest } = useServer();

    //STATES
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    //FUNCTIONS
    const cancel = () => {
        setOpenAddressDeleteModal(false);
        setAddressToDelete(-1);
    }

    const postDelete = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setErrorMsg("");

        const data = {
            user_address_id : addressToDelete
        };

        postRequest(endpoints.user_address_delete, data, {}, (res) => {
            setLoading(false);

            if (res[0]) {
                if (selectedAddress === addressToDelete) {
                    setSelectedAddress(-1);
                }

                loadUserAddresses();
                cancel();
            } else {
                setErrorMsg("Error del servidor, intenta de nuevo en un momento");
            }
        });
    }

    return (
        <div className="delete_address_modal">
            <div className='container'>
                <div className='header'>
                    <h2>
                        Eliminar dirección
                    </h2>
                </div>
                <div className='body'>
                    {
                        errorMsg !== "" && (
                            <div className='error_msg'>
                                {errorMsg}
                            </div>
                        )
                    }
                    <p>
                        ¿Seguro que quieres eliminar esta dirección de forma permanente? Este cambio no afectará las compras 
                        existentes
                    </p>
                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                </div>
                <div className='footer'>
                    <div>

                    </div>
                    <div className='btns'>
                        <button className='secundary_btn' onClick={cancel} disabled={loading}>
                            Cancelar
                        </button>
                        <button className='primary_btn' disabled={loading} onClick={postDelete}>
                            Eliminar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}