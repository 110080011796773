import './create_address_modal.css';
import logo from '../../assets/img/png/logo_alternativo.png';
import { useEffect, useState } from 'react';
import { useServer } from '../../context/ServerContext';
import { apiEndpoints, endpoints } from '../../env';
import { BarLoader } from '../BarLoader/BarLoader';
import { useUserAddresses } from '../../context/UserAddressesContext';

export const CreateAddressModal = () => {
    //HOOKS
    const { getRequest, postRequest } = useServer();
    const { setOpenAddressCreate, loadUserAddresses } = useUserAddresses();

    //STATES
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState(-1);
    const [cities, setCities] = useState([]);

    const [fullName, setFullName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [city, setCity] = useState("");
    const [streeType, setStreeType] = useState("Avenida");
    const [street, setStreet] = useState("");
    const [houseNumber1, setHouseNumber1] = useState("");
    const [houseNumber2, setHouseNumber2] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [addressDetails, setAddressDetails] = useState("");

    const [nameError, setNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [stateError, setStateError] = useState("");
    const [cityError, setCityError] = useState("");
    const [streetError, setStreetError] = useState("");
    const [neighborhoodError, setNeighborhoodError] = useState("");

    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const resetInputErrors = () => {
        setNameError("");
        setPhoneError("");
        setStateError("");
        setCityError("");
        setStreetError("");
        setNeighborhoodError("");
    }

    const validateInputs = () => {
        let passed = true;

        if (fullName.trim() === "") {
            setNameError("Ingresa el nombre completo");
            passed = false;
        }

        if (contactNumber.trim() === "") {
            setPhoneError("Ingresa un numero de contacto");
            passed = false;
        }

        if (selectedState === -1) {
            setStateError("Selecciona el departamento");
            passed = false;
        }

        if (city === "") {
            setCityError("Selecciona la ciudad");
            passed = false;
        }

        if (street.trim() === "") {
            setStreetError("Ingresa la calle");
            passed = false;
        }

        if (neighborhood.trim() === "") {
            setNeighborhoodError("Ingresa el barrio");
            passed = false;
        }

        return passed;
    }

    const postCreate = () => {
        if (loading) {
            return;
        }

        resetInputErrors();
        if (!validateInputs()) {
            return;
        }

        setLoading(true);

        const data = {
            fullName,
            contactNumber,
            state: states.find(state => state.id === selectedState).name,
            city,
            streeType,
            street,
            houseNumber1,
            houseNumber2,
            neighborhood,
            addressDetails,
        }

        postRequest(endpoints.user_address_create, data, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                loadUserAddresses();
                setOpenAddressCreate(false);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        getRequest(apiEndpoints.colombia_states, {}, {withCredentials: false}, (res) => {
            if (res[0]) {
                res = res[1];
                setStates(res);
            }
        });
    }, []);

    useEffect(() => {
        if (selectedState !== -1) {
            getRequest(apiEndpoints.colombia_state_cities.replace("{id}", selectedState), {}, {withCredentials: false}, (res) => {
                if (res[0]) {
                    setCities(res[1]);
                    setCity("");
                }
            });
        } else {
            setCities([]);
            setCity("");
        }
    }, [selectedState]);

    //EVENT HANDLERS
    const stateChange = (e) => {
        setSelectedState(parseInt(e.target.value));
    }

    return (
        <>
            <div className='create_address_modal'>
                <div className='header'>
                    <div className='logo'>
                        {/* <img src="https://www.gobio.com/wp-content/uploads/2020/12/light-logo-04.png" alt="" /> */}
                        <img src={logo} alt="" />
                    </div>
                </div>
                <div className='body'>
                    <h2>
                        Agregar dirección
                    </h2>
                    <div className='form_container'>
                        <div className='fancy_input'>
                            <label>
                                Nombre y apellido
                            </label>
                            <input type='text' value={fullName} onChange={(e) => setFullName(e.target.value)} />
                            {
                                nameError !== "" && <span>{nameError}</span>
                            }
                        </div>
                        <div className='fancy_input'>
                            <label>
                                Telefono de contacto
                            </label>
                            <input type='text' value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                            {
                                phoneError !== "" && <span>{phoneError}</span>
                            }
                        </div>
                        <div className='fancy_input_group'>
                            <div className='fancy_input'>
                                <label>
                                    Departamento
                                </label>
                                <select value={selectedState} onChange={stateChange}>
                                    <option defaultChecked value="-1">Selecciona el departamento</option>
                                    {
                                        states.map((state) => {
                                            return <option value={state.id} key={"address_create_state_" + state.id}>{state.name}</option>
                                        })
                                    }
                                </select>
                                {
                                    stateError !== "" && <span>{stateError}</span>
                                }
                            </div>
                            <div className='fancy_input'>
                                <label>
                                    Municipio
                                </label>
                                <select disabled={cities.length === 0} value={city} onChange={(e) => setCity(e.target.value)}>
                                    <option defaultChecked value="">Selecciona la ciudad</option>
                                    {
                                        cities.map((city) => {
                                            return <option value={city.name} key={"address_create_city_" + city.id}>{city.name}</option>
                                        })
                                    }                 
                                </select>
                                {
                                    cityError !== "" && <span>{cityError}</span>
                                }
                            </div>
                        </div>
                        <div className='fancy_input_group address_container'>
                            <div className='fancy_input street_type'>
                                <label>
                                    Tipo de calle
                                </label>
                                <select value={streeType} onChange={(e) => setStreeType(e.target.value)}>
                                    <option value="Avenida">Avenida</option>
                                    <option value="Avenida Calle">Avenida Calle</option>
                                    <option value="Avenida Carrera">Avenida Carrera</option>
                                    <option value="Calle">Calle</option>
                                    <option value="Carrera">Carrera</option>
                                    <option value="Circular">Circular</option>
                                    <option value="Circunvalar">Circunvalar</option>
                                    <option value="Diagonal">Diagonal</option>
                                    <option value="Manzana">Manzana</option>
                                    <option value="Transversal">Transversal</option>
                                    <option value="Vía">Vía</option>
                                </select>
                            </div>
                            <div className='fancy_input'>
                                <label>
                                    Calle
                                </label>
                                <input type='text' value={street} onChange={(e) => setStreet(e.target.value)} />
                                {
                                    streetError !== "" && <span>{streetError}</span>
                                }
                            </div>
                            <div className='fancy_input address_num'>
                                <label>
                                    Número
                                </label>
                                <input type='text' placeholder='#' value={houseNumber1} onChange={(e) => setHouseNumber1(e.target.value)} />
                            </div>
                            <p className='num_separator'>
                                -
                            </p>
                            <div className='fancy_input address_num'>
                                <label>&nbsp;</label>
                                <input type='text' value={houseNumber2} onChange={(e) => setHouseNumber2(e.target.value)} />
                            </div>
                        </div>
                        <div className='fancy_input'>
                            <label>
                                Barrio
                            </label>
                            <input type='text' value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)} />
                            {
                                neighborhoodError !== "" && <span>{neighborhoodError}</span>
                            }
                        </div>
                        <div className='fancy_input'>
                            <label>
                                Nota para el envio
                            </label>
                            <textarea 
                                placeholder='Instrucciones de entrega, como llegar al lugar...' 
                                value={addressDetails} 
                                onChange={(e) => setAddressDetails(e.target.value)}
                            ></textarea>
                        </div>
                        {
                            loading && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                        <div className='actions'>
                            <div></div>
                            <div className='btns'>
                                <button className='secundary_btn' disabled={loading} onClick={() => setOpenAddressCreate(false)}>
                                    Cancelar
                                </button>
                                <button className='primary_btn' onClick={postCreate} disabled={loading}>
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}