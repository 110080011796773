import './product_search_item.css';
import imagePlaceholder from '../../assets/img/jpg/product_placeholder.jpg';
import { useEffect, useState } from 'react';
import { backendEnv } from '../../env';
import { useNavigate } from 'react-router-dom';

export const ProductSearchItem = ({product}) => {
    //HOOKS
    const navigate = useNavigate();
    
    //STATES
    const [image, setImage] = useState(imagePlaceholder);

    //EFFECTS
    useEffect(() => {
        if (product.image) {
            setImage(backendEnv.url + product.image.path);
        }
    }, [product]);

    return (
        <div className="product_search_item" onClick={() => navigate('/catalogo/product/' + product.id, {replace: true})}>
            <div className='pic'>
                <img src={image} alt='' />
            </div>
            <div className='name'>
                <h2>
                    {product.name}
                </h2>
            </div>
        </div>
    );
}