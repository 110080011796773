import './address_modal_item.css';
import { useUserAddresses } from '../../context/UserAddressesContext';
import { Link } from 'react-router-dom';

export const AddressModalItem = ({address, editMode}) => {
    //HOOKS
    const { 
        selectedAddress, 
        setSelectedAddress, 
        saveSelectedAddress,
        setOpenAddressDeleteModal,
        setAddressToDelete,
    } = useUserAddresses();

    //FUNCTIONS
    const openDelete = () => {
        setAddressToDelete(address.id);
        setOpenAddressDeleteModal(true);
    }

    //EVENT HANDLERS
    const setSelected = () => {
        if (editMode) {
            saveSelectedAddress(address.id, (res) => {
            });
        }
        
        setSelectedAddress(address.id);
    }

    return (
        <div className="addresses_modal_address_item" onClick={setSelected}>
            <div className='select'>
                <input type='radio' checked={selectedAddress === address.id} onChange={setSelected} />
            </div>
            <div className='info'>
                <h2>
                    {address.streeType} {address.street} {address.houseNumber1} - {address.houseNumber2}
                </h2>
                <p>
                    {address.neighborhood} - {address.city}, {address.state} | {address.fullName} - {address.contactNumber}
                </p>
            </div>
            {
                editMode && (
                    <Link className='delete' onClick={openDelete}>
                        Eliminar
                    </Link>
                )
            }
        </div>
    );
};