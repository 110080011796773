import './purchase.css';
import { useParams } from "react-router-dom";
import { StoreHeader } from "../../components/StoreHeader/StoreHeader";
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useEffect, useState } from 'react';
import { useServer } from '../../context/ServerContext';
import { useCart } from '../../context/CartContext';
import { PurchaseProductItem } from '../../components/PurchaseProductItem/PurchaseProductItem';
import { DELIVERY_METHOD, endpoints } from '../../env';
import { Link } from 'react-router-dom';
import { DeliveryDetails } from '../../components/DeliveryDetails/DeliveryDetails';

export const Purchase = () => {
    const {orderId} = useParams();
    const {getRequest} = useServer();
    const {getLocalePrice} = useCart();

    //STATES
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [orderState, setOrderState] = useState("Procesando");

    //EFFECTS
    useEffect(() => {
        setLoading(true);
        setErrorMsg("");
        getRequest(endpoints.ecommerce_order_get, {order_id: orderId}, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];
                setOrder(res.order);
            } else {
                setErrorMsg("Error del servidor, intenta de nuevo en un momento.");
            }
        });
    }, [orderId]);

    useEffect(() => {
        if (order !== null) {
            const receivedDate = new Date(order.created_at);
            const options = {
                timeZone: "America/Bogota",
                hour12: true, // Use 24-hour time format
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            };
        
            setOrderDate(receivedDate.toLocaleString("es-CO", options));

            switch (order.state) {
                case 0:
                    setOrderState("Procesando");
                    break;
                case 1:
                    setOrderState("Pendiente de pago");
                    break;
                case 2:
                    setOrderState("Verificando pago");
                    break;
                case 3:
                    setOrderState("Pago rechazado");
                    break;
                case 4:
                    setOrderState("Pago exitoso");
                    break;
                case 5:
                    setOrderState("Preparando el pedido");
                    break;
                case 6:
                    setOrderState("Enviando pedido");
                    break;
                case 7:
                    setOrderState("Compra entregada");
                    break;
                default:
                    setOrderState("Procesando");
                    break;
            }
        }
    }, [order]);

    return (
        <>
            <StoreHeader />
            <div className="purchase_app">
                <div className='title'>
                    <h2>
                        Pedido #{order && order.id} - {orderDate}
                        <Link className='primary_btn' to={'/compras'}>
                            Ver todas las compras
                        </Link>
                    </h2>
                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                    {
                        errorMsg !== "" && (
                            <div className='error_msg'>
                                {errorMsg}
                            </div>
                        )
                    }
                </div>

                <div className='col1'>
                    <div className='products_container'>
                        <div className='header'>
                            <h2>Productos ({order && order.items.length})</h2>
                        </div>
                        <div className='body'>
                            {
                                order && order.items.map((item, index) => {
                                    return <PurchaseProductItem item={item} key={'purchase_' + Purchase.id + "_item_" + index} />
                                })
                            }
                        </div>
                    </div>
                    
                    {
                        order && order.deliveryMethod === DELIVERY_METHOD.DELIVERY && <DeliveryDetails deliveryDetails={order.deliveryDetails} />
                    }
                    
                </div>

                <div className='col2'>
                    <div className='order_pricing_summary'>
                        <div className='order_state'>
                            <div className='header'>
                                <h2>
                                    Estado de la compra
                                </h2>
                            </div>
                            <div className='body'>
                                <h2>
                                    {orderState}
                                </h2>
                            </div>
                        </div>
                        <div className='order_summary'>
                            <div className='header'>
                                <h2>
                                    Resumen de la compra
                                </h2>
                            </div>
                            <div className='body'>
                                <div className='item'>
                                    <div>
                                        Productos ({order && order.items.length})
                                    </div>
                                    <div>
                                        {order && getLocalePrice(order.subtotal)}
                                    </div>
                                </div>
                                <div className='item total'>
                                    <div>
                                        Total
                                    </div>
                                    <div>
                                        {order && getLocalePrice(order.total)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}