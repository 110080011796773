import './product_added.css';
import imagePlaceholder from '../../assets/img/jpg/product_placeholder.jpg';
import { StoreHeader } from "../../components/StoreHeader/StoreHeader";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCart } from '../../context/CartContext';
import { backendEnv } from '../../env';
import { Link } from 'react-router-dom';

export const ProductAdded = () => {
    //HOOKS
    const [searchParams, setSearchParams] = useSearchParams();
    const { cart } = useCart();
    const navigate = useNavigate();

    //STATES
    const [item, setItem] = useState(null);
    const [image, setImage] = useState(imagePlaceholder);

    //EFFECTS
    useEffect(() => {
        if (cart !== null) {
            const token = searchParams.get('token');
            setItem(cart.cartItems.find(item => item.token === token) || null);
        }
    }, [cart]);

    useEffect(() => {
        if (item !== null) {
            if (item.productData.image) {
                setImage(backendEnv.url + item.productData.image.path);
            }
        }
    }, [item]);

    return (
        <>
            <StoreHeader />
            <div className="product_added_app">
                <div className='container'>
                    <div className='pic'>
                        <img src={image} alt='' />
                    </div>
                    <div className='info'>
                        <h2>Producto agregado al carrito</h2>
                        <p>
                            { item && item.productData.name}
                        </p>
                    </div>
                    <div className='actions'>
                        <button className='secundary_btn' onClick={() => navigate('/compra/envio', {replace: true})}>
                            Comprar carrito
                        </button>
                        <Link to={'/carrito'} className='primary_btn'>
                            Ver carrito
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}