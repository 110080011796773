import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../../context/AuthContext";
import { useEffect } from "react";

export const ProtectRoute = ({path}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuth } = useAuth();

    useEffect(() => {
        if (location) {
            if (location.pathname === path && isAuth === false) {
                navigate('/login', {replace: true});
            }
        }
    }, [location, isAuth]);

    return <></>
}