import './single_product.css';
import imagePlaceholder from '../../assets/img/jpg/product_placeholder.jpg';
import { Footer } from '../../components/Footer/Footer';
import { StoreHeader } from '../../components/StoreHeader/StoreHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useServer } from '../../context/ServerContext';
import { backendEnv, endpoints } from '../../env';
import { Link } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { QtyInput } from '../../components/QtyInput/QtyInput';
import { useAuth } from '../../context/AuthContext';
import { useAuthModal } from '../../context/AuthModalContext';

export const SingleProduct = () => {
    //HOOKS
    const { productId } = useParams();
    const { getRequest, postRequest } = useServer();
    const { getLocalePrice, loadCart } = useCart();
    const navigate = useNavigate();
    const { isAuth } = useAuth();
    const { setLoginOpen } = useAuthModal();

    //STATES
    const [product, setProduct] = useState(null);
    const [image, setImage] = useState(imagePlaceholder);
    const [qty, setQty] = useState(1);
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState("");

    //FUNCTIONS
    const addToCart = () => {
        if (isAuth === null || isAuth === false) {
            setLoginOpen(true);
            return;
        }

        if (loading) {
            return;
        }

        setLoading(true);
        setErrorMsg("");

        const data = {
            product_id: productId,
            qty,
            variations: getVariations(),
        }

        postRequest(endpoints.ecommerce_cart_item_add, data, {}, (res) => {
            setLoading(false);

            if (res[0]) {
                res = res[1];

                if (res.success) {
                    loadCart();
                    navigate("/product/added?token=" + res.token, {replace: true});
                } else {
                    if (res.error_code === "product_unavailable") {
                        setErrorMsg("Este producto ya no se encuentra disponible.");
                    } else if (res.error_code === "unavailable_stock") {
                        setErrorMsg("No hay suficientes unidades de este producto, unidades disponibles: " + res.stock);
                    } else {
                        setErrorMsg("Error del servidor. Intenta de nuevo en un momento");
                    }
                }
            } else {
                setErrorMsg("Error del servidor. Intenta de nuevo en un momento");
            }
        });
    }

    const getVariations = () => {
        let variations = [];
        for (let i = 0; i < product.variations.length; i++) {
            variations.push({
                "name" : product.variations[i].name,
                "option" : document.querySelector('#variation_' + product.variations[i].id).value,
            });
        }

        return variations;
    }

    const calculatePercentageDiscount = (regularPrice, salePrice) => {
        const discountAmount = regularPrice - salePrice;
        const percentageDiscount = (discountAmount / regularPrice) * 100;
        const roundedPercentageDiscount = Math.round(percentageDiscount);
      
        return roundedPercentageDiscount;
    }

    //EFFECTS
    useEffect(() => {
        getRequest(endpoints.ecommerce_product_get_by_id, { product_id: productId }, {}, (res) => {
            if (res[0]) {
                res = res[1];
                setProduct(res.product);
            }
        });
    }, []);

    useEffect(() => {
        if (product !== null) {
            if (product.image) {
                setImage(backendEnv.url + product.image.path);
            }
        }
    }, [product]);

    return (
        <>
            <div className='store_body'>
                <StoreHeader />

                <div className='single_product_body'>
                    {
                        errorMsg !== "" && (
                            <div className='error_msg'>
                                {errorMsg}
                            </div>
                        )
                    }
                    <div className='links'>
                        <div></div>
                        <Link to={'/catalogo'} className='back'>
                            Volver
                        </Link>
                    </div>
                    <div className='main_info_container'>
                        <div className='product_main'>
                            <div className='product_image'>
                                <div className='current_image'>
                                    <img className='blur' src={image} alt='' />
                                    <img src={image} alt='' />
                                </div>
                            </div>
                            <div className='product_information'>
                                <h1 className='name'>
                                    {product && product.name}
                                </h1>
                                {/* {
                                    product && product.saleActive === 1 && (
                                        <span className='price_before'>
                                            {getLocalePrice(product.regularPrice)}
                                        </span>
                                    )
                                } */}
                                
                                {/* <div className='price'>
                                    <span>
                                        {product && (product.saleActive === 1 ? getLocalePrice(product.salePrice) : getLocalePrice(product.regularPrice))}
                                    </span>
                                    {
                                        product && product.saleActive === 1 && (
                                            <p>
                                                {calculatePercentageDiscount(product.regularPrice, product.salePrice)}% OFF
                                            </p>
                                        )
                                    }
                                </div> */}

                                {
                                    product && product.variations.map((variation) => {
                                        return (
                                            <div className='fancy_input' key={variation.id}>
                                                <label>{variation.name}</label>
                                                <select id={'variation_' + variation.id}>
                                                    {
                                                        variation.options.map((option) => {
                                                            return (
                                                                <option value={option.name}>{option.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        )
                                    })
                                }

                                {/* <QtyInput initalValue={1} callback={setQty} /> */}

                                <div className='actions'>
                                    {
                                        loading && (
                                            <div className='loader'>
                                                <BarLoader />
                                            </div>
                                        )
                                    }
                                    
                                    {/* <button>
                                        Comprar ahora
                                    </button> */}
                                    {/* <button className='add' disabled={loading} onClick={addToCart}>
                                        Agregar al carrito
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div className='product_description'>
                            <h2>
                                Descripción
                            </h2>
                            <p>
                                {
                                    product && product.description
                                }
                            </p>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
}