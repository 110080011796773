export const backendEnv = {
    url: 'https://backendatze.onlycarmanizales.com',
    websiteId: 1,
}

export const endpoints = {
    auth_login: '/public/api/v1/auth/login',
    auth_check: '/public/api/v1/auth/check',
    auth_logout: '/public/api/v1/auth/logout',
    user_register: '/public/api/v1/auth/register',
    user_address_list: '/public/api/v1/user/address/list',
    user_address_create: '/public/api/v1/user/address/create',
    user_address_update: '/public/api/v1/user/address/update',
    user_address_delete: '/public/api/v1/user/address/delete',
    user_update: '/public/api/v1/user/update',
    ecommerce_product_list: '/public/api/v1/ecommerce/products/get',
    ecommerce_product_get_by_id: '/public/api/v1/ecommerce/products/get_by_id',
    ecommerce_categories_list: '/public/api/v1/ecommerce/categories/get',
    ecommerce_cart_get: '/public/api/v1/ecommerce/cart/get',
    ecommerce_cart_item_update: '/public/api/v1/ecommerce/cart/item/update',
    ecommerce_cart_item_delete: '/public/api/v1/ecommerce/cart/item/delete',
    ecommerce_cart_item_add: '/public/api/v1/ecommerce/cart/item/add',
    ecommerce_order_get: '/public/api/v1/ecommerce/order/get',
    ecommerce_order_get_all: '/public/api/v1/ecommerce/order/get/all',
    ecommerce_order_create: '/public/api/v1/ecommerce/order/create',
    ecommerce_order_get_state: '/public/api/v1/ecommerce/order/state/get',
}

export const apiEndpoints = {
    colombia_states : 'https://api-colombia.com/api/v1/Department',
    colombia_state_cities : 'https://api-colombia.com/api/v1/Department/{id}/cities',
}

export const PAYMENT_METHODS = {
    CASH: 0,
    WOMPI: 1,
    SISTECREDITO: 2,
}

export const ORDER_STATES = {
    PROCESSING : 0,
    PAYMENT_PENDING : 1,
    PAYMENT_VERIFYING : 2,
    PAYMENT_FAIL : 3,
    PAYMENT_SUCCESSFULL : 4,
    ORDER_PROCESSING : 5,
    ORDER_DELIVERYNG : 6,
    ORDER_COMPLETED : 7,
}

export const DELIVERY_METHOD = {
    PICKUP : 0,
    DELIVERY : 1,
}

export const ORDER_ERRORS = {
    
}