import './header_product_search.css';
import { useGlobalClick } from '../../context/GlobalClickContext';
import { useServer } from '../../context/ServerContext';
import { backendEnv, endpoints } from '../../env';
import { BarLoader } from '../BarLoader/BarLoader';
import { useEffect, useRef, useState } from 'react';
import { ProductSearchItem } from '../ProductSearchItem/ProductSearchItem';

export const HeaderProductSearch = () => {
    //HOOKS
    const { addClickCallback, removeClickCallback } = useGlobalClick();
    const { getRequest } = useServer();

    //REFS
    const searchRef = useRef();

    //STATES
    const [loading, setLoading] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [products, setProducts] = useState([]);

    //FUNCTIONS
    const handleGlobalClick = (e) => {
        if (searchRef.current) {
            if (!searchRef.current.contains(e.target)) {
                setSearchOpen(false);
            }

            if (searchRef.current.contains(e.target) && query !== "") {
                setSearchOpen(true);
            }
        }
    }

    const search = () => {
        let data = {
            atze_website_id: backendEnv.websiteId,
            query,
        };

        setLoading(true);
        getRequest(endpoints.ecommerce_product_list, data, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];
                setProducts(res.products);
            }
        });
    }
    
    //EFFECTS
    useEffect(() => {
        if (query !== "") {
            setSearchOpen(true);
            search();
        } else {
            setSearchOpen(false);
            setProducts([]);
        }

        addClickCallback(handleGlobalClick);

        return () => {
            removeClickCallback(handleGlobalClick);
        };
    }, [query]);
    return (
        <div className='header_search' ref={searchRef}>
            <div className='search_input'>
                <input type='text' placeholder='Busca productos' value={query} onChange={(e) => setQuery(e.target.value)} />
                <button>
                    <i className="fa-solid fa-magnifying-glass"></i>
                </button>
            </div>
            {
                searchOpen && (
                    <div className='search_results'>
                        <div className='header'>
                            <h2>
                                Resultados de la busqueda
                            </h2>
                        </div>
                        {
                            loading && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                        <div className='body'>
                            {
                                products.map((product) => {
                                    return <ProductSearchItem product={product} key={'product_search_item_' + product.id} />
                                })
                            }
                            {
                                products.length === 0 && (
                                    <div className='noresults'>
                                        No se encontraron productos
                                    </div>
                                )
                            }
                        </div>
                        {
                            products.length > 0 && (
                                <div className='footer'>
                                    <div></div>
                                    <div>
                                        {/* <button className='primary_btn'>
                                            Ver todos los resultados
                                        </button> */}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}