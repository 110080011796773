import './purchase_payment_verification.css';
import { useNavigate, useParams } from "react-router-dom";
import { StoreHeader } from "../../components/StoreHeader/StoreHeader";
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useEffect, useState } from 'react';
import { useServer } from '../../context/ServerContext';
import { ORDER_STATES, PAYMENT_METHODS, endpoints } from '../../env';
import { useAuth } from '../../context/AuthContext';
import { useRef } from 'react';

export const PurchasePaymentVerification = () => {
    //HOOKS
    const { orderId } = useParams();
    const { getRequest } = useServer();
    const { user } = useAuth();
    const navigate = useNavigate();

    //REFFS
    const wompiCheckoutRef = useRef(null);

    //STATES
    const [order, setOrder] = useState(null);
    const [status, setStatus] = useState(0);

    //FUNCTIONS
    const openWompiWidget = () => {
        if (wompiCheckoutRef.current === null) {
            wompiCheckoutRef.current = new window.WidgetCheckout({
                currency: 'COP',
                amountInCents: order.total,
                reference: order.paymentDetails.reference,
                publicKey: 'pub_test_qlQNoESo9Vtql7yMqsEJmYw6LkKtszPn',
                //redirectUrl: 'http://localhost:3000/compra/pago',
                // customerData: user ? {
                //     email: user ? user.email : "",
                //     fullName: (user ? user.name : "") + " " + (user ? user.lastname : ""),
                //     phoneNumber: user ? user.phone : "",
                //     phoneNumberPrefix: '+57',
                // } : {},
            });

            wompiCheckoutRef.current.open(function ( result ) {
                setTimeout(() => {
                    updateState();
                }, 500);
            });
        }
    }

    const forceOpenWompiWidget = () => {
        wompiCheckoutRef.current.open(function ( result ) {
            console.log(result);
        });
    }

    const updateState = () => {
        const data = {
            order_id : orderId,
        }

        getRequest(endpoints.ecommerce_order_get_state, data, {}, (res) => {
            if (res[0]) {
                res = res[1];
                setStatus(res.state);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        getRequest(endpoints.ecommerce_order_get, {order_id: orderId}, {}, (res) => {
            if (res[0]) {
                setOrder(res[1].order);
            }
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            updateState();
        }, 5000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if (order !== null) {
            if (order.paymentMethod === PAYMENT_METHODS.WOMPI) {
                if (order.state === ORDER_STATES.PROCESSING || order.state === ORDER_STATES.PAYMENT_PENDING) {
                    openWompiWidget();
                }
            }

            if (order.paymentMethod === PAYMENT_METHODS.SISTECREDITO) {
                window.location.href = order.paymentDetails.sistecreditoUrl + "?transactionId=" + order.paymentDetails.transactionId;
            }
        }
    }, [order]);

    return (
        <>
            <StoreHeader />
            <div className="payment_verification_app">
                <div className='payment_verification_container'>
                    {
                        (status === ORDER_STATES.ORDER_COMPLETED ||
                        status === ORDER_STATES.ORDER_DELIVERYNG ||
                        status === ORDER_STATES.PAYMENT_SUCCESSFULL) &&
                        (
                            <h2>
                                Pago recibido
                            </h2>
                        )
                    }
                    {
                        (status === ORDER_STATES.ORDER_PROCESSING ||
                        status === ORDER_STATES.PAYMENT_PENDING ||
                        status === ORDER_STATES.PAYMENT_VERIFYING ||
                        status === ORDER_STATES.PROCESSING) && 
                        (
                        <h2>
                            Verificando el pago
                        </h2>
                        )
                    }
                    {
                        status === ORDER_STATES.PAYMENT_FAIL &&
                        (
                        <h2>
                            Pago rechazado
                        </h2>
                        )
                    }
                    {
                        status !== ORDER_STATES.ORDER_COMPLETED &&
                        status !== ORDER_STATES.ORDER_DELIVERYNG &&
                        status !== ORDER_STATES.PAYMENT_FAIL &&
                        status !== ORDER_STATES.PAYMENT_SUCCESSFULL &&
                        (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }   
                    <p>
                        Si ya realizaste el pago
                        el pedido será confirmado automáticamente, enviaremos
                        una confirmación a tu correo registrado.
                    </p>
                    {
                        (order && order.paymentMethod === 1 &&
                        status !== ORDER_STATES.ORDER_COMPLETED &&
                        status !== ORDER_STATES.ORDER_DELIVERYNG &&
                        status !== ORDER_STATES.PAYMENT_FAIL &&
                        status !== ORDER_STATES.PAYMENT_SUCCESSFULL) &&
                        (
                            <button className='primary_btn' onClick={forceOpenWompiWidget}>
                                Realizar pago con Wompi
                            </button>
                        )
                    }
                    {
                        (status === ORDER_STATES.ORDER_COMPLETED ||
                        status === ORDER_STATES.ORDER_DELIVERYNG ||
                        status === ORDER_STATES.PAYMENT_FAIL ||
                        status === ORDER_STATES.PAYMENT_SUCCESSFULL) && (
                            <button className='primary_btn' onClick={() => navigate('/compras', {replace: true})}>
                                Mis compras
                            </button>
                        )
                    }
                </div>
            </div>
        </>
    );
}