import './purchase_payment.css';
import WompiLogo from '../../assets/img/png/Wompi_LogoPrincipal.png';
import LogoSistecredito from '../../assets/img/png/logo-sistecredito.png';
import { StoreHeader } from "../../components/StoreHeader/StoreHeader";
import { useAuth } from "../../context/AuthContext";
import { useCart } from '../../context/CartContext';
import { usePurchaseProccess } from '../../context/PurchaseProccessContext';
import { useUserAddresses } from '../../context/UserAddressesContext';
import { useServer } from '../../context/ServerContext';
import { endpoints } from '../../env';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useState } from 'react';

export const PurchasePayment = () => {
    //HOOKS
    const { user } = useAuth();
    const { cart, getLocalePrice, loadCart } = useCart();
    const { postRequest } = useServer();
    const { 
        deliveryType, 
        paymentMethod, 
        setPaymentMethod,
        cedula,
        setCedula
    } = usePurchaseProccess();
    const { selectedAddress } = useUserAddresses();
    const navigate = useNavigate();

    //STATES
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    //FUNCTIONS
    const postOrder = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setErrorMsg("");
        
        let data = {
            delivery_method: deliveryType,
            delivery_to: selectedAddress,
            payment_method: paymentMethod,
        };

        if (paymentMethod === 2) {
            data.document = cedula;

            if (cedula.trim() === "") {
                setLoading(false);
                setErrorMsg("Ingresa la cédula para realizar el pago con sistecrédito");
                return;
            }
        }

        postRequest(endpoints.ecommerce_order_create, data, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];
                if (res.success) {
                    navigate('/compra/'+ res.order.id +'/pago/verificacion');
                    loadCart();
                } else {
                    if (res.error_code === "sistecredito_progress") {
                        setErrorMsg("Ya existe un crédito en progreso con esta cédula, por favor finaliza el proceso anterior antes de continuar.");
                    }
                }
            } else {
                setErrorMsg("Error del servidor, por favor intenta de nuevo en un momento");
            }
        });
    }

    return (
        <>
            <StoreHeader />
            <div className="purchase_payment_app">
                <div className='payment_methods_container'>
                    <h2 className='title'>
                        Selecciona tu metodo de pago
                    </h2>
                    {
                        errorMsg !== "" && (
                            <div className='error_msg' style={{marginBottom: "10px"}}>
                                {errorMsg}
                            </div>
                        )
                    }
                    <div className='method_container'>
                        <div className='header'>

                        </div>
                        <div className='item' onClick={() => setPaymentMethod(1)}>
                            <input type='radio' checked={paymentMethod === 1} onChange={() => setPaymentMethod(1)} />
                            <img className='logo' src={WompiLogo} alt='' />
                            <p>
                                Tarjetas de crédito y débito, pagos con PSE, Bancolombia, Nequi y corresponsal bancario
                            </p>
                        </div>
                        <div className='item' onClick={() => setPaymentMethod(2)}>
                            <input type='radio' checked={paymentMethod === 2} onChange={() => setPaymentMethod(2)} />
                            <img className='logo sistecredito' src={LogoSistecredito} alt='' />
                            <p>
                                Paga a credito
                            </p>
                            {
                                paymentMethod === 2 && (
                                    <div className='fancy_input' style={{marginLeft: "10px"}}>
                                        <input type='text' placeholder='Cedula' value={cedula} onChange={(e) => setCedula(e.target.value)} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className='purchase_summary_container'>
                    <div className='summary_container'>
                        <div className='header'>
                            <h2>
                                Resumen de la compra
                            </h2>
                        </div>
                        {
                            loading && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                        <div className='body'>
                            <div className='item'>
                                <h2>
                                    Productos ({ cart && cart.cartItems.length})
                                </h2>
                                <span>
                                    { cart && getLocalePrice(cart.cartData.subtotal)}
                                </span>
                            </div>
                            <div className='item'>
                                <h2>
                                    Envío
                                </h2>
                                <span>
                                    {
                                        deliveryType === 1 && (
                                            <>
                                                { cart && getLocalePrice(cart.cartData.deliveryPrice)}
                                            </>
                                        )
                                    }
                                    {
                                        deliveryType === 0 && (
                                            <>
                                                { cart && getLocalePrice(0)}
                                            </>
                                        )
                                    }
                                </span>
                            </div>
                            <div className='item'>
                                <h2>
                                    Total
                                </h2>
                                <span>
                                    {
                                        deliveryType === 1 && (
                                            <>
                                                { cart && getLocalePrice(cart.cartData.total)}
                                            </>
                                        )
                                    }
                                    {
                                        deliveryType === 0 && (
                                            <>
                                                { cart && getLocalePrice(cart.cartData.total - cart.cartData.deliveryPrice)}
                                            </>
                                        )
                                    }
                                </span>
                            </div>
                            <button className='primary_btn' onClick={postOrder} disabled={loading}>
                                Completar compra
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}