import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { backendEnv, endpoints } from "../env";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(undefined);

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const api = axios.create({
        baseURL: backendEnv.url,
        withCredentials: true,
    });

    //STATES
    const [isAuth, setIsAuth] = useState(null);
    const [user, setUser] = useState(null);

    //FUNCTIONS
    const checkAuth = () => {
        api.get(endpoints.auth_check, {headers: {'Authorization': 'Bearer ' + getToken()}}).then(res => {
            res = res.data;
            if (res.success) {
                setIsAuth(res.success);
                setUser(res.user);
            } else {
                setIsAuth(false);
                setUser(null);
            }
        }).catch((res) => {
            // setIsAuth(false);
        });
    }

    const login = (email, password, callback) => {
        const data = {
            atze_website_id: backendEnv.websiteId,
            email,
            password,
        }

        api.post(endpoints.auth_login, data).then(res => {
            let data = res.data;

            if (data.success) {
                setToken(data.session.token);
            }

            callback([true, data]);
        }).catch((res) => {
     
        });
    }

    const logout = () => {
        api.post(endpoints.auth_logout, {}, {headers: {'Authorization': 'Bearer ' + getToken()}}).then(res => {
            setIsAuth(false);
            setUser(null);
            setToken("");
            navigate('/', {replace: true});
        }).catch((res) => {
            // setIsAuth(false);
        });
    }

    const getToken = () => {
        const cookies = new Cookies();
        return cookies.get("user_token");
    }

    const setToken = (token) => {
        const cookies = new Cookies();
        let d = new Date();
        d.setTime(d.getTime() + (10 * 365 * 24 * 60 * 60 * 1000));

        cookies.set("user_token", token, {
            expires: d,
        });

        checkAuth();
    }

    //EFFECTS

    useEffect(() => {
        checkAuth();
    }, []);
    
    const values = {
        isAuth,
        user,
        checkAuth,
        getToken,
        setToken,
        login,
        logout,
    }

    return (
        <AuthContext.Provider
            value={values}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);